import styled from 'styled-components';
import { Badge } from 'antd';

export const BadgeStyled = styled(Badge)`
  .ant-badge-status-cyan {
    background: #0ec6ad;
  }
`;

export const WrapperBadgeStyled = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
`;
