import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { axios } from '../../../packages/keycloak-client/exios';
import { useChatListState } from '../../../pages/chat-list/state/useChatListState';
import { CHAT_URL, EXCHANGE_MAP_API_URL, FINMS_URL_API, MAP_URL } from '../../../packages/keycloak-client/constants';
import { produce } from 'immer';
import { getCookieByName, setCookieByName } from '../../../shared/helpers/controlCookies';
import { Base64ToBlob } from '../helpers/Base64ToBlob';
import { useChatTokensState } from '../../../state/useChatTokensState';

type TPartnersChat = {
    client_uid: string;
    closed_at: string | null;
    created_at: string | null;
    deleted_at: string | null;
    entity_type: string | null;
    has_referee: boolean;
    id: number;
    last_message_at: null | string;
    last_message_read_id: null | string;
    offer_deal: null | number;
    offer: null | any;
    offer_deal_id: null | string;
    offer_id: null | string;
    participant_id: string;
    participant_type: string;
    unread_count: number;
};

type TMessagesState = {
    phexChat: any;
    partnersChat: any;
    messages: [];
    isFetchingMessages: true;
    totalMessages: number;
    currentPage: number;
    topMessageDate: string;
    messageLimit: number;
    searchMessages: (payload: TSearchMessagesPayload) => Promise<void>;
    addMessage: (payload: TAddMessagePayload) => Promise<string | number>;
    createChat: (payload: TCreateChatPayload) => Promise<void>;
    setIsFetchingMessages: (value: boolean) => void;
    createAttachment: (payload: TSearchAttachmentsPayload) => Promise<{
        lastUploadLink: string;
        lastCreatedAttachmentId: number | string;
    }>;
    uploadAttachmentToMinio: (payload: TUploadAttachmentToMinio) => Promise<void>;
    changeIsUploadInAttachment: ({
                                     attachment_id,
                                 }: TChangeIsUploadInAttachment) => Promise<void>;
    getAttachment: ({attachment_id}: TGetAttachment) => Promise<string>;
    getPartnersChat: ({offer_deal_id}: TGetPartnersChat) => Promise<void>;
    getPhexChat: ({offer_id}: TGetPhexChat) => Promise<void>;
    clearMessages: () => void;
    getChatInfo: ({entity_type, entity_id, client_uid}: TGetChatInfo) => Promise<void>;
    chatInfo: TChatInfo;
    addMessageToMessages: (message: any) => void;
    getAdminWSChatToken: () => Promise<void>;
    resetFields: () => void;
};

type TSearchMessagesPayload = {
    entity_type: string | null;
    entity_id: string;
    client_uid: string | undefined;
    auth_token: string | undefined;
};

type TAddMessagePayload = {
    entity_type: string | null;
    entity_id: string;
    client_uid?: string;
    message: string;
    auth_token: string | undefined;
};

type TCreateChatPayload = {
    offer_id: string;
    cookie: any;
};

type TSearchAttachmentsPayload = {
    message_id: string | null | number;
    file_name: string;
    file_extension: string | undefined;
    auth_token: string | undefined;
};

type TUploadAttachmentToMinio = {
    files: any;
    upload_link: string;
    type: string | undefined;
};

type TChangeIsUploadInAttachment = {
    attachment_id: string | number | null;
    auth_token: string | undefined;
};

type TGetAttachment = {
    attachment_id: string | number | null;
    auth_token: string | undefined;
};

type TGetPartnersChat = {
    offer_deal_id?: string;
    offer_id?: string;
};

type TGetPhexChat = {
    offer_id?: string;
    offer_deal_id?: string;
};

type TGetChatInfo = {
    entity_type: string | null;
    entity_id: string;
    client_uid: string | undefined;
    auth_token: string | undefined;
};

type TChatInfo = {
    client_uid: string;
    closed_at: null | string;
    created_at: null | string;
    entity_type: string | null;
    entity_id: string;
    has_referee: boolean;
    participants: any[]
}

export const useMessagesState = create<TMessagesState>()(
    devtools(
        (set, get) => ({
            isFetchingMessages: true,
            totalMessages: 0,
            currentPage: 1,
            messages: [],
            topMessageDate: '',
            messageLimit: 20,
            partnersChat: {} as TPartnersChat,
            phexChat: {} as TPartnersChat,
            chatInfo: {} as TChatInfo,
          resetFields: () => {
            set(
              produce((draft) => {
                draft.isFetchingMessages = true
                draft.totalMessages = 0
                draft.currentPage = 1
                draft.messages = []
                draft.topMessageDate = ''
                draft.messageLimit = 20
                draft.partnersChat = {} as TPartnersChat
                draft.phexChat = {} as TPartnersChat
                draft.chatInfo = {} as TChatInfo
              })
            )
          },
            getAdminWSChatToken: async () => {
                try {
                    const res = await fetch(`${CHAT_URL}/server/chat/api/ws/token`, {
                        method: 'get',
                        headers: {
                            Authorization: `Bearer ${getCookieByName('hubChatToken')?.replace(
                                'Bearer',
                                ''
                            )}`
                        }
                    })
                    // Parse the JSON response
                    const data = await res.json();
                    setCookieByName('wsChatToken', data?.token);
                    set(
                        produce((draft) => {
                            draft.wssChannels = data?.channels;
                        })
                    )

                } catch (e) {
                    console.error('getWSChatToken error', e);
                }
            },
            addMessageToMessages: (message: any) => {
                set(
                    produce((draft) => {
                        draft.messages = [...draft.messages, message];
                    })
                )
            },
            getChatInfo: async ({entity_type, entity_id, client_uid, auth_token}: TGetChatInfo) => {
                try {
                    const res = await axios.post(`${CHAT_URL}/server/chat/api/chat/info`, {
                        entity_type: entity_type,
                        entity_id: entity_id,
                        client_uid: client_uid
                    }, {
                        headers: {
                            Authorization: auth_token,
                        }
                    })

                    set(
                        produce((draft) => {
                            draft.chatInfo = res?.data?.data;
                        })
                    )
                } catch (e) {
                    console.error('getChatInfo e', e)
                }
            },
            clearMessages: () => {
                set(
                    produce((draft) => {
                        draft.messages = [];
                    })
                );
            },
            setIsFetchingMessages: (value: boolean) => {
                set(
                    produce((draft) => {
                        draft.isFetchingMessages = value;
                    })
                );
            },
            getPartnersChat: async ({
                                        offer_deal_id,
                                        offer_id,
                                    }: TGetPartnersChat) => {
                try {
                    const filters = [] as any[];

                    const calcFilters = () => {
                        if (offer_id) {
                            filters.push({
                                field: 'offer_id',
                                operator: '=',
                                value: offer_id,
                            });
                        } else if (offer_deal_id) {
                            filters.push({
                                field: 'offer_deal_id',
                                operator: '=',
                                value: offer_deal_id,
                            });
                        }
                    };

                    calcFilters();
                    const res = await axios.post(
                        `${EXCHANGE_MAP_API_URL}/partner/api/chats/search`,
                        {
                            filters,
                            includes: [
                                {
                                    relation: 'offer',
                                },
                                {
                                    relation: 'offer_deal',
                                },
                                {relation: 'offer.exchange_point.company'},
                                {relation: 'offer.cash_payment_system_currency'},
                                {relation: 'offer.crypto_payment_system_currency'},
                            ],
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${getCookieByName('token')?.replace(
                                    'Bearer',
                                    ''
                                )}`,
                            },
                        }
                    );
                    set(
                        produce((draft) => {
                            console.log(
                                'partnersChat res?.data?.data[0]',
                                res?.data?.data[0]
                            );
                            draft.partnersChat = res?.data?.data[0];
                        })
                    );
                } catch (e) {
                    console.error('getPartnersChatsList error:', e);
                } finally {
                    set(
                        produce((draft) => {
                            draft.isLoadingSkeleton = false;
                        })
                    );
                }
            },
            getPhexChat: async ({offer_id, offer_deal_id}: TGetPhexChat) => {
                try {
                    const filters = [] as any[];
                    const calcFilters = () => {
                        if (offer_id) {
                            filters.push({
                                field: 'offer_id',
                                operator: '=',
                                value: offer_id,
                            });
                        } else if (offer_deal_id) {
                            filters.push({
                                field: 'offer_deal_id',
                                operator: '=',
                                value: offer_deal_id,
                            });
                        }
                    };

                    calcFilters();

                    const res = await axios.post(
                        `${FINMS_URL_API}/server/api/chats/search`,
                        {
                            filters,
                            includes: [
                                {
                                    relation: 'offer',
                                },
                                {
                                    relation: 'offer_deal',
                                },
                                {relation: 'offer.exchange_point.company'},
                                {relation: 'offer.cash_payment_system_currency'},
                                {relation: 'offer.crypto_payment_system_currency'},
                            ],
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${getCookieByName('token')?.replace(
                                    'Bearer',
                                    ''
                                )}`,
                            },
                        }
                    );
                    set(
                        produce((draft) => {
                            draft.phexChat = res?.data?.data[0];
                        })
                    );
                } catch (e) {
                    console.log('getPhexChatsList error:', e);
                } finally {
                    set(
                        produce((draft) => {
                            draft.isLoadingSkeleton = false;
                        })
                    );
                }
            },
            createAttachment: async (payload: TSearchAttachmentsPayload) => {
                // step 1
                try {
                    const res = await axios.post(
                        `${CHAT_URL}/server/chat/api/attachment`,
                        {
                            message_id: payload.message_id,
                            file_name: payload.file_name,
                            file_extension: payload.file_extension,
                        },
                        {
                            headers: {
                                Authorization: payload?.auth_token,
                            },
                        }
                    );
                    return Promise.resolve({
                        lastUploadLink: res?.data?.data?.upload_link,
                        lastCreatedAttachmentId: res?.data?.data?.id,
                    });
                } catch (e) {
                    console.error('searchAttachment step 1 e', e);
                    if (useChatListState.getState().typeTabs === 'personal') {
                        await useChatTokensState.getState().getPartnerChatToken();
                    } else {
                        await useChatTokensState.getState().getPhexChatToken();
                    }
                    return Promise.reject();
                }
            },
            uploadAttachmentToMinio: async (payload: TUploadAttachmentToMinio) => {
                // step 2
                const [header, base64] = payload.files[0].preview.split(',');
                const contentType = header.split(':')[1].split(';')[0];
                const blob = Base64ToBlob(base64, contentType);

                try {
                    await fetch(payload.upload_link, {
                        method: 'put',
                        body: blob,
                        headers: {
                            'Content-Type': payload?.type ? payload?.type : '',
                        },
                    });
                    return Promise.resolve();
                } catch (e) {
                    console.error('uploadAttachmentToMinio step 2 e', e);
                    return Promise.reject();
                }
            },
            changeIsUploadInAttachment: async ({
                                                   attachment_id,
                                                   auth_token
                                               }: TChangeIsUploadInAttachment) => {
                // step 3
                try {
                    await axios.put(
                        `${CHAT_URL}/server/chat/api/attachment/${attachment_id}`,
                        {
                            is_uploaded: true,
                        },
                        {
                            headers: {
                                Authorization: auth_token
                            },
                        }
                    );
                    return Promise.resolve();
                } catch (e) {
                    console.error('changeIsUploadInAttachment step 3 e', e);
                    if (useChatListState.getState().typeTabs === 'personal') {
                        await useChatTokensState.getState().getPartnerChatToken();
                    } else {
                        await useChatTokensState.getState().getPhexChatToken();
                    }
                    return Promise.reject();
                }
            },
            getAttachment: async ({attachment_id, auth_token}: TGetAttachment) => {
                try {
                    const res = await axios.get(
                        `${CHAT_URL}/server/chat/api/attachment/${attachment_id}`,
                        {
                            headers: {
                                Authorization: auth_token,
                            },
                        }
                    );
                    return Promise.resolve(res?.data?.data?.download_link);
                } catch (e) {
                    console.error('getAttachment e', e);
                    if (useChatListState.getState().typeTabs === 'personal') {
                        await useChatTokensState.getState().getPartnerChatToken();
                    } else {
                        await useChatTokensState.getState().getPhexChatToken();
                    }
                    return Promise.reject();
                }
            },
            searchMessages: async (payload: TSearchMessagesPayload) => {
                useMessagesState?.getState().setIsFetchingMessages(true);
                try {
                    const res = await axios.post(
                        `${CHAT_URL}/server/chat/api/message/search?limit=${get().messageLimit}?page=${
                            get().currentPage
                        }`,
                        {
                            entity_type: payload.entity_type,
                            entity_id: payload.entity_id,
                            client_uid: payload.client_uid,
                            sort: [
                                {
                                    field: 'created_at',
                                    direction: 'desc',
                                },
                            ],
                        },
                        {
                            headers: {
                                Authorization: payload?.auth_token
                            },
                        }
                    );
                    const newMessages = res?.data?.data?.reverse() || [];

                    set(
                        produce((draft) => {
                            const combinedMessages = [...draft.messages, ...newMessages];
                            const uniqueMessages = Array.from(
                                new Map(combinedMessages.map((msg) => [msg.id, msg])).values()
                            );
                            draft.totalMessages = res?.data?.meta?.total;
                            draft.topMessageDate =
                                res?.data?.data[res?.data?.data.length - 1]?.created_at;
                            draft.messages = uniqueMessages;
                            draft.currentPage = draft.currentPage + 1;
                        }),
                        false,
                        {
                            type: 'useMessagesState => searchMessages',
                        }
                    );
                } catch (e) {
                    console.log('searchMessages error:', e);
                    if (useChatListState.getState().typeTabs === 'personal') {
                        await useChatTokensState.getState().getPartnerChatToken();
                    } else {
                        await useChatTokensState.getState().getPhexChatToken();
                    }
                } finally {
                    useMessagesState?.getState().setIsFetchingMessages(false);
                }
            },
            addMessage: async (payload: TAddMessagePayload) => {
                try {
                    const res = await axios.post(
                        `${CHAT_URL}/server/chat/api/message`,
                        {
                            entity_type: payload?.entity_type,
                            entity_id: payload?.entity_id,
                            client_uid: payload?.client_uid, // Для entity_type === offer
                            text: payload?.message,
                        },
                        {
                            headers: {
                                Authorization: payload?.auth_token
                            },
                        }
                    );
                    return Promise.resolve(String(res?.data?.data?.id));
                } catch (e) {
                    console.error('addMessage error:', e);
                    if (useChatListState.getState().typeTabs === 'personal') {
                        await useChatTokensState.getState().getPartnerChatToken();
                    } else {
                        await useChatTokensState.getState().getPhexChatToken();
                    }
                    return Promise.reject();
                }
            },
            createChat: async ({offer_id, cookie}: TCreateChatPayload) => {
                // Только для партнерки создание чата
                try {
                    await axios.post(
                        `${MAP_URL}/chats`,
                        {
                            offer_id: offer_id,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${cookie['token']}`,
                            },
                        }
                    );
                    return Promise.resolve();
                } catch (e) {
                    console.log('createChat e', e);
                    return Promise.reject();
                }
            },
        }),
        {
            anonymousActionType: 'useMessagesState action',
            name: 'messagesState',
        }
    )
);
