import {
  ChatListHeaderStyled,
  ChatListHeaderTitleStyled,
} from './assets/styles';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

type TChatListHeaderProps = {
  onCrossClick: () => void;
};

const ChatListHeader = ({ onCrossClick }: TChatListHeaderProps) => {
  const { t } = useTranslation();
  return (
    <ChatListHeaderStyled>
      <ChatListHeaderTitleStyled>{t(`Чаты`)}</ChatListHeaderTitleStyled>
      <Button
        size={'large'}
        type={'text'}
        icon={<CloseOutlined />}
        onClick={onCrossClick}
      />
    </ChatListHeaderStyled>
  );
};

export default ChatListHeader;
