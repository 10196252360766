import {
  ChatListItemOfferStatusRightStatus,
  ChatListItemOfferStatusRightStyled,
  ChatListItemOfferStatusRightTitleStyled,
  ChatListItemOfferStatusStyled,
} from './assets/styles';
import { useTranslation } from 'react-i18next';
import GreenDot from './assets/greenDot.svg';
import RedDot from './assets/redDot.svg';
import { useMemo } from 'react';

type TChatListItemOfferStatus = {
  status?: boolean;
};

const ChatListItemOfferStatus = ({ status }: TChatListItemOfferStatus) => {
  const { t, i18n } = useTranslation();

  const renderDot = useMemo(() => {
    if (status) {
      return <img src={GreenDot} alt={'GreenDot'} />;
    } else {
      return <img src={RedDot} alt={'RedDot'} />;
    }
  }, [status, i18n.language]);

  const renderStatus = useMemo(() => {
    if (status) {
      return t('Активен');
    } else {
      return t('Не активен');
    }
  }, [status, i18n.language]);

  return (
    <ChatListItemOfferStatusStyled>
      {renderDot}
      <ChatListItemOfferStatusRightStyled>
        <ChatListItemOfferStatusRightTitleStyled>
          {t('Оффер')}
        </ChatListItemOfferStatusRightTitleStyled>
        <ChatListItemOfferStatusRightStatus>
          {renderStatus}
        </ChatListItemOfferStatusRightStatus>
      </ChatListItemOfferStatusRightStyled>
    </ChatListItemOfferStatusStyled>
  );
};

export default ChatListItemOfferStatus;
