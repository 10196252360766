import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { AdaptiveFullScreenLoader } from '../../../components/full-screen-loader';

interface InfinityScrollSpin {
  isLoading: boolean;
  incrementPageCallback: () => void;
  total: number;
  currentLengthList: number;
}

export const InfinityScrollSpin = ({
  isLoading,
  incrementPageCallback,
  total,
  currentLengthList,
}: InfinityScrollSpin) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const lastItemRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && total > currentLengthList) {
          incrementPageCallback();
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, total, currentLengthList]
  );

  return (
    <>
      {isLoading ? (
        <AdaptiveFullScreenLoader />
      ) : (
        <LastItemStyled ref={lastItemRef} />
      )}
    </>
  );
};

const LastItemStyled = styled.div`
  height: 0;
`;
