import styled from 'styled-components';
import { Badge, Typography } from 'antd';
import Flex from '../../../../../shared/ui/Flex';

export const ChatItemWrapper = styled.div<{ $is_buying_crypto?: boolean }>`
  display: flex;
  align-items: center;
  gap: 18px;
  cursor: pointer;
  padding: 24px 16px;
  height: 82px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-left: ${({ $is_buying_crypto }) =>
    $is_buying_crypto
      ? '2px solid rgba(8, 132, 115, 1)'
      : '2px solid rgba(99, 32, 229, 1)'};

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const BadgeStyled = styled(Badge)<{ $is_corporate: boolean }>`
  margin-bottom: ${({ $is_corporate }) => ($is_corporate ? '24px' : '0')};
  margin-left: auto;
`;

export const DirectionContainerStyled = styled(Flex)`
  width: 170px;
  justify-content: flex-start;
  align-items: center;
`;

export const TypographyStyled = styled(Typography.Text)`
  color: rgba(118, 118, 135, 1);
`;
