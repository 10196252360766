import { BadgeStyled, WrapperBadgeStyled } from './assets/styles';
import { useMemo } from 'react';

type TChatListItemMessageCounter = {
  count: number;
  isShow: boolean;
};

const ChatListItemMessageCounter = ({
  count,
  isShow,
}: TChatListItemMessageCounter) => {
  const renderBadge = useMemo(() => {
    if (isShow) {
      return <BadgeStyled showZero={true} color={'cyan'} count={count} />;
    }
  }, [count, isShow]);

  return <WrapperBadgeStyled>{renderBadge}</WrapperBadgeStyled>;
};

export default ChatListItemMessageCounter;
