import {notification, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {toNumber} from './helpers/toNumber';
import {useTranslation} from 'react-i18next';
import getLocalizedDate from './helpers/getLocalizedDate';
import {PageToolbar} from './components/page-toolbar';
import {CustomSettingsCard} from '../../components/ui/card/settings-card';
import {ChangePasswordModal} from './components/change-password-modal';
import {ChangeEmailModal} from './components/change-email-modal';
import {ChangeNicknameModal} from './components/change-nickname-modal';
import {useCredentialsState} from '../../state/useCredentialsState';
import styled from 'styled-components';
import {IconNotificationSuccess} from '../../components/ui/icons';
import {CloseIcon} from '../../assets/img/icons/icons';
import {useNavigate} from 'react-router-dom';
import {ROUTER_PATH} from '../../router/path';
import {FullScreenLoader} from '../../components/full-screen-loader';
import {useLoginState} from '../../state/useLoginState';
import {useCookiesCustom} from '../../hooks/useCookieCustom';
import {useAuthState} from '../../state/useAuthState';
import {useModalState} from '../../shared/ui/RenderModal/model/useModalState';

const AccountSettingsIndexPage = (): JSX.Element => {
    const {cookie} = useCookiesCustom();
    const {i18n, t} = useTranslation();
    const [showModalPassword, setShowModalPassword] = useState(false);
    const [showModalEmail, setShowModalEmail] = useState(false);
    const [showChangeNicknameModal, setShowChangeNicknameModal] = useState(false);
    const email = cookie['email'] || localStorage?.getItem('loginEmail') || '';
    const getCredentials = useCredentialsState((state) => state.getCredentials);
    const allCredentials = useCredentialsState((state) => state.allCredentials);
    const inviteToken = localStorage.getItem('inviteToken') || cookie.inviteToken;
    const navigate = useNavigate();
    const redirectUrl = localStorage.getItem('redirectUrl') || '';
    const setIsLoading = useLoginState((state) => state.setIsLoading);
    const userAPI = useAuthState((state) => state.userAPI);
    const getUserProfileFromAPI = useAuthState(
        (state) => state.getUserProfileFromAPI
    );
    const {openModal} = useModalState();
    useEffect(() => {
        if (!!cookie['token']) {
            getCredentials(cookie['token']).then();
            if (!!inviteToken && inviteToken !== 'undefined') {
                localStorage.setItem('inviteNotificationShown', String(false));
                setTimeout(() => {
                    navigate(
                        `${ROUTER_PATH.FormsPages.CONFIRM_INVITE}?inviteToken=${inviteToken}`
                    );
                }, 4000);
            }
        }

        if (!redirectUrl) return;
        else if (
            // (redirectUrl.includes('finmsapp')) ||
            !inviteToken ||
            inviteToken === 'undefined'
        ) {
            if (redirectUrl.includes('exchangemap')) {
                notification.success({
                    icon: <IconNotificationSuccess/>,
                    message: (
                        <span
                            style={{
                                fontSize: '16px',
                                fontWeight: '700',
                                color: '#FBFBFF',
                            }}
                        >
              {t('descriptions.redirectExchangeMap')}
            </span>
                    ),
                    description: '',
                    duration: 1,
                    style: {background: '#2B2B36'},
                    closeIcon: <CloseIcon/>,
                });
            }
        } else if (redirectUrl.includes('finmsapp')) {
            console.log('account-settings, redirectUrl', redirectUrl);
            notification.success({
                icon: <IconNotificationSuccess/>,
                message: (
                    <span
                        style={{
                            fontSize: '16px',
                            fontWeight: '700',
                            color: '#FBFBFF',
                        }}
                    >
            {t('descriptions.redirectFinMS')}
          </span>
                ),
                description: '',
                duration: 1,
                style: {background: '#2B2B36'},
                closeIcon: <CloseIcon/>,
            });
        }
    }, []);

    const showPasswordModal = () => {
        setShowModalPassword(true);
    };

    const showEmailModal = () => {
        setShowModalEmail(true);
    };

    const showChangeNicknameModalHandler = () => {
        setShowChangeNicknameModal(true);
    };

    let passwordCreate;
    if (Array.isArray(allCredentials) && !!allCredentials.length) {
        passwordCreate = allCredentials.map((item: any) => item.createdDate);
    }
    let passwordCreateDate = new Date(
        toNumber(passwordCreate?.[passwordCreate.length - 1])
    );

    const day = passwordCreateDate.getDate();
    const monthIndex = passwordCreateDate.getMonth();
    const year = passwordCreateDate.getFullYear();
    const hours = passwordCreateDate.getHours();
    const minutes =
        (passwordCreateDate.getMinutes() < 10 ? '0' : '') +
        passwordCreateDate.getMinutes();

    const hideEmail = (email: string): string => {
        return email?.replace(
            /(.{2})(.*)(?=@)/,
            function (gp1: string, gp2: string, gp3: string) {
                for (let i = 0; i < 8; i++) {
                    gp2 += '*';
                }
                return gp2;
            }
        );
    };

    const handleRefetchNickname = () => {
        getUserProfileFromAPI().then();
        setShowChangeNicknameModal(false);
    };

    useEffect(() => {
        setIsLoading(false);
    }, []);

    if (!!cookie['token']) {
        if (!!redirectUrl && redirectUrl !== 'undefined') {
            return <FullScreenLoader/>;
        }
        if (!!cookie.inviteToken && cookie.inviteToken !== 'undefined') {
            return <FullScreenLoader/>;
        }
    }

    return (
        <Space direction="vertical" style={{gap: 0}}>
            <PageToolbar
                title={t('titles.profileSettings')}
                subTitle={t('descriptions.profileSettings') as string}
            />
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '24px',
                }}
            >
                <CustomSettingsCard
                    cardTitle={t('titles.accountPassword')}
                    cardText={<span>{t('descriptions.accountPassword')}</span>}
                    isMenu={false}
                    isConnectedTag={false}
                    isTagWithoutIcon={true}
                    onClick={showPasswordModal}
                >
                    {passwordCreate && (
                        <SettingsCardBadgeStyled>
                            {t('statuses.installed') +
                                ' ' +
                                getLocalizedDate(day, monthIndex, year, hours, minutes, i18n)}
                        </SettingsCardBadgeStyled>
                    )}
                </CustomSettingsCard>

                {showModalPassword && (
                    <ChangePasswordModal
                        showModalPassword={showModalPassword}
                        setShowModalPassword={setShowModalPassword}
                    />
                )}

                <CustomSettingsCard
                    cardTitle={t('titles.profileEmail')}
                    cardText={<span>{t('descriptions.profileEmail')}</span>}
                    isMenu={false}
                    isConnectedTag={false}
                    isTagWithoutIcon={true}
                    onClick={showEmailModal}
                >
                    <SettingsCardBadgeStyled>{hideEmail(email)}</SettingsCardBadgeStyled>
                </CustomSettingsCard>

                <ChangeEmailModal
                    showModalEmail={showModalEmail}
                    setShowModalEmail={setShowModalEmail}
                />

                <CustomSettingsCard
                    cardTitle={t('titles.accountNickname')}
                    cardText={<span>{t('descriptions.profileNickname')}</span>}
                    isMenu={false}
                    isConnectedTag={false}
                    isTagWithoutIcon={true}
                    onClick={showChangeNicknameModalHandler}
                >
                    <div>{userAPI?.account?.nickname}</div>
                </CustomSettingsCard>

                {showChangeNicknameModal && (
                    <ChangeNicknameModal
                        refetch={handleRefetchNickname}
                        showModalNickname={showChangeNicknameModal}
                        setShowModalNickname={setShowChangeNicknameModal}
                    />
                )}
            </div>
        </Space>
    );
};

const SettingsCardBadgeStyled = styled.div`
    display: flex;
`;

export default AccountSettingsIndexPage;
