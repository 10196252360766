import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { EDENEX_ACCOUNT_URL } from '../packages/keycloak-client/constants';
import { produce } from 'immer';
import { notification } from 'antd';
import { t } from 'i18next';
import { axios } from '../shared/exios';

type TRegisterState = {
  nickname: string;
  email: string;
  password: string;
  passwordRepeat: string;
  isAgree: boolean;
  register: ({ isChangeStep = true }: TRegister) => Promise<void | Error>;
  registerConfirm: ({ token }: TRegisterConfirm) => Promise<Boolean>;
  setNickname: (value: string) => void;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  setPasswordRepeat: (value: string) => void;
  setIsAgree: () => void;
  reset: () => void;
  step: number;
  setStep: (value: number) => void;
};

type TRegisterConfirm = {
  token: string;
  cookie: any;
  removeCookie: any;
  navigate: any;
};

type TRegister = {
  isChangeStep?: boolean;
};

export const useRegisterState = create<TRegisterState>()(
  devtools(
    (set) => ({
      nickname: '',
      email: '',
      password: '',
      passwordRepeat: '',
      isAgree: false,
      step: 1,
      setStep: (value: number) => {
        set(
          produce((draft) => {
            draft.step = value;
          }),
          false,
          {
            type: 'useRegisterState => setStep',
          },
        );
      },
      register: async ({ isChangeStep = true }: TRegister) => {
        const email = useRegisterState.getState().email;
        const nickname = useRegisterState.getState().nickname;
        const redirectUrl = localStorage.getItem('redirectUrl') || '';

        const helperBool = () => {
          console.log('helperBool redirectUrl', redirectUrl);
          return redirectUrl.includes('finmsapp.');
        };

        try {
          await axios.post(
            `${EDENEX_ACCOUNT_URL}/edenex-account/api/registration`,
            {
              nickname,
              email,
              password: useRegisterState.getState().password,
              password_confirmation: useRegisterState.getState().passwordRepeat,
              is_finms: helperBool(),
            },
          );
          localStorage.setItem('email', email);
          if (!!isChangeStep) {
            localStorage.setItem('registerStep', String(2));
            set(
              produce((draft) => {
                draft.step = 2;
              }),
              false,
              {
                type: 'useRegisterState => setStep to 2',
              },
            );
          }
          if (helperBool()) {
            localStorage.removeItem('toCreate');
          }
        } catch (e: any) {
          console.log('register error', e);
          return Promise.reject(e.response.data);
        }
      },
      registerConfirm: async ({ token, cookie, removeCookie, navigate }: TRegisterConfirm) => {
        try {
          await axios.get(
            `${EDENEX_ACCOUNT_URL}/edenex-account/api/registration-confirm?token=${token}`,
          );
          if (!localStorage.getItem('inviteToken') || localStorage.getItem('inviteToken') === 'undefined')
            notification.success({
              message: t('registerConfirmation.successMessage'),
              description: t('registerConfirmation.successDesc'),
              duration: 3,
            });
          set(
            produce((draft) => {
              draft.reset();
            }),
          );
          return true;
        } catch (e) {
          console.log('registerConfirm error', e);
          notification.error({
            message: t('registerConfirmation.errorMessage'),
            duration: 3,
          });
          return false;
        }
      },
      setNickname: (value: string) => {
        set(
          produce((draft) => {
            draft.nickname = value;
          }),
          false,
          {
            type: 'useRegisterState => setNickname',
          },
        );
      },
      setEmail: (value: string) => {
        set(
          produce((draft) => {
            draft.email = value;
          }),
          false,
          {
            type: 'useRegisterState => setEmail',
          },
        );
      },
      setPassword: (value: string) => {
        set(
          produce((draft) => {
            draft.password = value;
          }),
          false,
          {
            type: 'useRegisterState => setPassword',
          },
        );
      },
      setPasswordRepeat: (value: string) => {
        set(
          produce((draft) => {
            draft.passwordRepeat = value;
          }),
          false,
          {
            type: 'useRegisterState => setPasswordRepeat',
          },
        );
      },
      setIsAgree: () => {
        set(
          produce((draft) => {
            draft.isAgree = !draft.isAgree;
          }),
          false,
          {
            type: 'useRegisterState => setIsAgree',
          },
        );
      },
      reset: () => {
        set(
          produce((draft) => {
            draft.step = 1;
            draft.nickname = '';
            draft.email = '';
            draft.password = '';
            draft.passwordRepeat = '';
            draft.isAgree = false;
            localStorage.removeItem('registerStep');
          }),
          false,
          {
            type: 'useRegisterState => reset',
          },
        );
      },
    }),
    {
      anonymousActionType: 'useRegisterState action',
      name: 'useRegisterState',
    },
  ),
);
