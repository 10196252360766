import styled from "styled-components";

export const ChatListItemOfferStepTimerStyled = styled.div`
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: #DEDEE3;
`;
