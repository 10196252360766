import styled from 'styled-components';

export const ChatContainerStyled = styled.div`
    background-color: transparent;
    overflow: hidden;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
`;
