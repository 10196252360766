import { styled } from 'styled-components';

export const ChatListStyled = styled.div`
  display: flex;
  height: 100%;
  gap: 10px;
  margin-right: -30px;
  transition: margin-right 0.5s ease;
`;

export const ChatListLeftStyled = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ $isOpen }) => ($isOpen ? '75%' : '100%')};
  transition: width 0.5s ease;
`;

export const ChatListRightStyled = styled.div<{ $isOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ $isOpen }) => ($isOpen ? '25%' : 0)};
  border-left: ${({ $isOpen }) => ($isOpen ? '1px solid #413d50' : 'none')};
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.5s ease;
`;
