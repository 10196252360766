import ChatModule from '../ChatModule/ChatModule';
import { useChatListState } from '../../pages/chat-list/state/useChatListState';
import { useMessagesState } from '../ChatModule/states/useMessagesState';
import { useEffect } from 'react';
import { useChatTokensState } from '../../state/useChatTokensState';
import { Centrifuge } from 'centrifuge';
import { WS_URL } from '../../packages/keycloak-client/constants';
import { useCookiesCustom } from '../../hooks/useCookieCustom';
import { calcAuthToken } from '../ChatModule/helpers/calcAuthToken';

const ChatListWrapper = () => {
    const {cookie} = useCookiesCustom();
    const currentChat = useChatListState((state) => state.currentChat);
    const getChatInfo = useMessagesState((state) => state.getChatInfo);
    const setIsOpenChat = useChatListState((state) => state.setIsOpenChat);
    const chatInfo = useMessagesState((state) => state.chatInfo);
    const opponent = chatInfo?.participants?.find((participant) => !!participant?.is_opponent);
    const searchMessages = useMessagesState((state) => state.searchMessages);
    const totalMessages = useMessagesState((state) => state.totalMessages);
    const currentPage = useMessagesState((state) => state.currentPage);
    const isFetchingMessages = useMessagesState((state) => state.isFetchingMessages);
    const messageLimit = useMessagesState((state) => state.messageLimit);
    const setIsFetchingMessages = useMessagesState((state) => state.setIsFetchingMessages);
    const addMessageToMessages = useMessagesState((state) => state.addMessageToMessages);
    const messages = useMessagesState((state) => state.messages);
    const wssChannels = useChatTokensState((state) => state.wssChannels);
    const getWSChatToken = useChatTokensState((state) => state.getWSChatToken);
    const openChatId = useChatListState((state) => state.openChatId);
    const createAttachment = useMessagesState((state) => state.createAttachment);
    const addMessage = useMessagesState((state) => state.addMessage);
    const changeIsUploadInAttachment = useMessagesState((state) => state.changeIsUploadInAttachment);
    const uploadAttachmentToMinio = useMessagesState((state) => state.uploadAttachmentToMinio);
    const resetFields = useMessagesState((state) => state.resetFields);
    const typeTabs = useChatListState((state) => state.typeTabs);

    const centrifuge = new Centrifuge(WS_URL, {
        token: cookie['wsChatToken'],
        getToken: async () => {
            getWSChatToken().then();
            return cookie['wsChatToken'];
        }
    });

    useEffect(() => {
        async function subscribeToChannel({channelName}: { channelName: string }) {
            const chatUserSub = centrifuge.newSubscription(channelName);
            chatUserSub.on('publication', function (pubData) {
                const {data} = pubData;
                if (data?.type === 'new_message') {
                    addMessageToMessages(data?.message);
                }
            }).subscribe();
        }

        getWSChatToken().then(async () => {
            if (!!wssChannels.length) {
                for (const channelName of wssChannels) {
                    await subscribeToChannel({channelName})
                }
            }
        }).finally(() => {
            centrifuge.connect();
        });

        return () => {
            centrifuge.disconnect();
        }
    }, [])

    const calcEntityId = () => {
        return currentChat?.entity_type === 'offer' ? currentChat?.offer_id : currentChat?.offer_deal?.id
    }

    useEffect(() => {
        if (currentChat?.entity_type && currentChat?.client_uid && currentChat?.entity_type) {
            getChatInfo({
                entity_type: currentChat?.entity_type,
                entity_id: calcEntityId(),
                client_uid: currentChat?.client_uid,
                auth_token: calcAuthToken({chatType: 'list', typeTabs}),
            }).then()
        }
    }, [currentChat?.entity_type, currentChat?.entity_id, currentChat?.client_uid, openChatId])

    useEffect(() => {
      // resetFields()
        if ((messageLimit * currentPage < totalMessages || currentPage === 1)) {
            if (currentChat) {
                searchMessages({
                    entity_type: currentChat?.entity_type,
                    entity_id: calcEntityId(),
                    client_uid: currentChat?.client_uid,
                    auth_token: calcAuthToken({chatType: 'list', typeTabs})
                }).then()
            }
        }
    }, [currentPage, totalMessages, messageLimit, openChatId]);

    return (
        <ChatModule
            isShowTimer={true}
            isClosableChat={true}
            currentChat={currentChat}
            isReferee={currentChat?.has_referee}
            setIsOpenChat={setIsOpenChat}
            opponent={opponent}
            currentPage={currentPage}
            setIsFetchingMessages={setIsFetchingMessages}
            searchMessages={searchMessages}
            messageLimit={messageLimit}
            totalMessages={totalMessages}
            isFetchingMessages={isFetchingMessages}
            messages={messages}
            addMessage={addMessage}
            changeIsUploadInAttachment={changeIsUploadInAttachment}
            createAttachment={createAttachment}
            uploadAttachmentToMinio={uploadAttachmentToMinio}
            calcEntityId={calcEntityId}
            entity_type={currentChat?.entity_type}
            client_uid={currentChat?.client_uid}
            typeTabs={typeTabs}
            chatType={'list'}
        />
    )
}

export default ChatListWrapper;
