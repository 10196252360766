import {ChatContainer} from '../components/container';
import {ChatContent} from '../components/content';
import ChatForm from '../components/form';
import {ChatHeader} from '../components/header';
import {ChatMessageContainer} from "../components/messages-container";

type TChat = {
    isReferee: boolean,
    isClosableChat: boolean,
    setIsOpenChat: (value: boolean) => void,
    opponent: {
        type: "user" | "exchange_point" | "company" | undefined,
        created_at: string,
        caption: string
    } | undefined,
    currentChat: any,
    currentPage: number,
    setIsFetchingMessages: (value: boolean) => void,
    searchMessages: (value: any) => Promise<void>,
    messageLimit: number,
    totalMessages: number,
    isFetchingMessages: boolean,
    messages: any[],
    addMessage: (value: any) => Promise<any>,
    changeIsUploadInAttachment: (value: any) => Promise<any>
    createAttachment: (value: any) => Promise<any>
    uploadAttachmentToMinio: (value: any) => Promise<any>
    calcEntityId: () => number | string;
    isShowTimer: boolean;
    entity_type: string;
    client_uid: string | number | undefined;
    typeTabs: string;
    chatType: 'list' | 'deal' | 'admin' | undefined;
}

const Chat = ({
                  isReferee,
                  isClosableChat,
                  setIsOpenChat,
                  opponent,
                  currentChat,
                  setIsFetchingMessages,
                  currentPage,
                  searchMessages,
                  messageLimit,
                  totalMessages,
                  isFetchingMessages,
                  messages,
                  addMessage,
                  changeIsUploadInAttachment,
                  createAttachment,
                  uploadAttachmentToMinio,
                  calcEntityId,
                  isShowTimer,
                  entity_type,
                  client_uid,
                  typeTabs,
                  chatType
              }: TChat): JSX.Element => {
    return (
        <ChatContainer>
            <ChatHeader
                isReferee={isReferee}
                isClosableChat={isClosableChat}
                setIsOpenChat={setIsOpenChat}
                opponent={opponent}
                currentChat={currentChat}
                isShowTimer={isShowTimer}
            />
            <ChatContent>
                <ChatMessageContainer
                    currentPage={currentPage}
                    setIsFetchingMessages={setIsFetchingMessages}
                    messageLimit={messageLimit}
                    totalMessages={totalMessages}
                    isFetchingMessages={isFetchingMessages}
                    messages={messages}
                />
                <ChatForm
                    addMessage={addMessage}
                    changeIsUploadInAttachment={changeIsUploadInAttachment}
                    createAttachment={createAttachment}
                    searchMessages={searchMessages}
                    uploadAttachmentToMinio={uploadAttachmentToMinio}
                    calcEntityId={calcEntityId}
                    entity_type={entity_type}
                    client_uid={client_uid}
                    typeTabs={typeTabs}
                    chatType={chatType}
                />
            </ChatContent>
        </ChatContainer>
    );
};

export {Chat};
