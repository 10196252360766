import {ChatListFiltersStyled, TabLabelStyled, TabsStyled,} from './assets/styles';
import {TTabs, useChatListState} from '../../state/useChatListState';
import {CorporateIcon} from '../../../../assets/img/icons/icons';
import {UserOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {Badge, TabsProps} from 'antd';
import {ReactNode} from 'react';
import {useChatAsideState} from 'widgets/chatAside/model/useChatAsideState';

type TRenderTabLabel = {
    label: string;
    count: number;
    children?: ReactNode;
    onClick: () => void;
};

const ChatListFilters = ({
                             typeTabs,
                             setTypeTabs,
                         }: {
    typeTabs: TTabs;
    setTypeTabs: (value: TTabs) => void;
}) => {
    const resetFields = useChatListState((state) => state.resetFields);
    const getPartnersChatsList = useChatListState(
        (state) => state.getPartnersChatsList
    );
    const getPhexChatsList = useChatListState((state) => state.getPhexChatsList);
    // const phexChatsTotalPages = useChatListState(
    //   (state) => state.phexChatsTotalPages
    // );
    // const chatListTotalPages = useChatListState(
    //   (state) => state.chatListTotalPages
    // );
    const setCurrentPage = useChatListState((state) => state.setCurrentPage);
    const deletePartnersChatsList = useChatListState(
        (state) => state.deletePartnersChatsList
    );
    const deletePhexChatsList = useChatListState(
        (state) => state.deletePhexChatsList
    );
    const chatCounts = useChatAsideState((state) => state.chatCounts);
    // const userFinmsApi = useAuthState((state) => state.userFinmsApi);

    const {t} = useTranslation();

    const renderTabLabel = ({
                                label,
                                count,
                                onClick,
                                children,
                            }: TRenderTabLabel) => {
        return (
            <TabLabelStyled onClick={onClick}>
                {children}
                {label}
                <Badge color="cyan" count={count} showZero={true}/>
            </TabLabelStyled>
        );
    };

    const tabs: TabsProps['items'] = [
        {
            label: renderTabLabel({
                label: t('Личные'),
                count: chatCounts.personal,
                onClick: () => {
                    resetFields();
                    setCurrentPage(1);
                    deletePhexChatsList();
                    getPartnersChatsList();
                },
                children: <UserOutlined style={{margin: 0}}/>,
            }),
            key: 'personal',
        },
        {
            label: renderTabLabel({
                label: t('Корпоративные'),
                count: chatCounts.corporate,
                onClick: () => {
                    resetFields();
                    setCurrentPage(1);
                    deletePartnersChatsList();
                    getPhexChatsList();
                },
                children: <CorporateIcon/>,
            }),
            key: 'corporate',
        },
    ];

    return (
        <ChatListFiltersStyled>
            <TabsStyled
                defaultActiveKey={typeTabs}
                items={tabs}
                onChange={(val) => setTypeTabs(val as TTabs)}
            />
            {/*// TODO: Добавить фильтры*/}
        </ChatListFiltersStyled>
    );
};

export default ChatListFilters;
