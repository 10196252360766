import { ChatListItemOfferStepTimerStyled } from './assets/styles';
import GenericTimer from 'shared/ui/GenericTimer';
import { css, styled } from 'styled-components';
import { useTranslation } from 'react-i18next';

const ChatListItemOfferStepTimer = ({
  closedAt,
}: {
  closedAt: null | string;
}) => {
  const { t } = useTranslation();
  return (
    <ChatListItemOfferStepTimerStyled>
      <TimerWrapperStyled>
        <GenericTimer deadline={closedAt ? closedAt : '0'} />
      </TimerWrapperStyled>
    </ChatListItemOfferStepTimerStyled>
  );
};

export default ChatListItemOfferStepTimer;

export const TimerWrapperStyled = styled.div<{
  $isTimerDimmed?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  color: #ffffff80;

  .ant-statistic {
    font-size: 16px;
    .ant-statistic-content {
      padding-top: 8px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
    }
  }

  ${({ $isTimerDimmed }) =>
    $isTimerDimmed &&
    css`
      .ant-statistic {
        .ant-statistic-content {
          color: #474755;
        }
      }
    `}
`;
