import React, {useMemo} from "react";
import dayjs from 'dayjs';
import {
    UserInfoContainerDescStyled,
    UserInfoContainerStyled,
    UserInfoContainerTitleStyled,
    UserInfoNicknameStatusStyled,
    UserInfoWrapper
} from "./assets/styles";
import CloseAlert from "../../../form/assets/CloseAlert.svg";
import {UserAvatar} from "../../../userAvatar/UserAvatar";
import {useChatContext} from "../../../context";
import {useTranslation} from "react-i18next";
import {Button} from "antd";
// TODO: Добавить и интегрировать, после доработки на бэке
// import StatisticTooltip from "../../../StatisticTootip/StatisticTooltip";

type TChatHeaderContainerUserInfo = {
    isReferee: boolean,
    isClosableChat: boolean,
    setIsOpenChat: (value: boolean) => void,
    opponent: {
        type: "user" | "exchange_point" | "company" | undefined,
        created_at: string,
        caption: string
    } | undefined
}

const ChatHeaderContainerUserInfo = ({
                                         isClosableChat,
                                         opponent,
                                         isReferee,
                                         setIsOpenChat
                                     }: TChatHeaderContainerUserInfo) => {
    const {t} = useTranslation();
    const {user} = useChatContext();

    const renderUserInfo = useMemo(() => {
        const chatDesc = () => {
            if (opponent?.type === 'exchange_point') {
                return `${t('чат.Создан')} ${dayjs(opponent?.created_at).format('DD.MM.YY')}`;
            } else if (opponent?.type === 'user') {
                return `${t('чат.Зарегистрирован')} ${dayjs(opponent?.created_at).format('DD.MM.YY')}`;
            }
        }

        const handleCloseChat = () => {
            if (typeof opponent === "undefined") {
                if (window.history.length > 1) {
                    window.history.back();
                }
            } else {
                setIsOpenChat(false);
            }
        }

        return (
            <UserInfoWrapper>
                <UserInfoContainerStyled>
                    <UserAvatar
                        user={user}
                        size={'large'}
                        type={opponent?.type}
                        isReferee={isReferee}
                    />
                    <UserInfoNicknameStatusStyled>
                        <UserInfoContainerTitleStyled>
                            {opponent?.caption || t('Сделка')}
                        </UserInfoContainerTitleStyled>
                        {/*<StatisticTooltip*/}
                        {/*    type={type}*/}
                        {/*    isConnectedFinMS={isConnectedFinMS}*/}
                        {/*    completeDeals={completeDeals}*/}
                        {/*    outTimeDeals={outTimeDeals}*/}
                        {/*    cancelledDeals={cancelledDeals}*/}
                        {/*    disputesCount={disputesCount}*/}
                        {/*>*/}
                        <UserInfoContainerDescStyled>
                            {chatDesc()}
                            {/*<InfoCircleOutlined/>*/}
                        </UserInfoContainerDescStyled>
                        {/*</StatisticTooltip>*/}
                    </UserInfoNicknameStatusStyled>
                </UserInfoContainerStyled>
                {isClosableChat && (
                    <Button type={'ghost'} onClick={handleCloseChat}>
                        <img src={CloseAlert} alt={'close'}/>
                    </Button>
                )}
            </UserInfoWrapper>
        )
    }, [
        t,
        user,
        isReferee,
        opponent
    ])


    return (<>{renderUserInfo}</>)
}

export default ChatHeaderContainerUserInfo;
