import {Button, Form, Input, Modal, notification, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import Icon from '@ant-design/icons';
import {CloseIcon} from '../../../../assets/img/icons/icons';
import styled from 'styled-components';
import {t} from 'i18next';
import {axios} from '../../../../shared/exios';
import {EDENEX_ACCOUNT_URL} from "../../../../packages/keycloak-client/constants";
import {useCookiesCustom} from '../../../../hooks/useCookieCustom';

interface IChangeEmailModal {
    showModalEmail: boolean;
    setShowModalEmail: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeEmailModal = ({
                              showModalEmail,
                              setShowModalEmail,
                          }: IChangeEmailModal): JSX.Element => {
    const {cookie} = useCookiesCustom();
    const [form] = Form.useForm();
    const [btnSubmitDisabled, setBtnSubmitDisabled] = useState(false);
    const [showModalEmailConfirm, setShowModalEmailConfirm] = useState(false);
    const [sameError1, setSameError1] = useState(false);
    const [sameError2, setSameError2] = useState(false);
    const [SomethingError1, setSomethingError1] = useState(false);
    const [SomethingError2, setSomethingError2] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const handleCancelEmailModal = () => {
        setShowModalEmail(false);
        setBtnSubmitDisabled(true);
    };

    const handleClickEmailConfirm = () => {
        validateFields().then(() => {
            if (
                form.getFieldsError()[0].errors.length ||
                form.getFieldsError()[1].errors.length
            ) {
                setBtnSubmitDisabled(true);
            } else {
                postFormData().then();
            }
        });
    };

    const handleFieldValueChange = () => {
        form.validateFields().then();
    };

    const checkErrors = async (event: any, value: string) => {
        const emailRegex =
            /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])*$/;
        try {
            if (!value || !value.length) {
                return Promise.reject(new Error(`${t('titles.enterEmail')}`));
            }
            if (!emailRegex.test(value)) {
                return Promise.reject(
                    new Error(t('inputs.errors.incorrectEmailAbbreviated').toString()),
                );
            }
            if (
                form.getFieldValue('newEmail') !==
                form.getFieldValue('newEmailConfirm') &&
                event.field === 'newEmailConfirm'
            ) {
                return Promise.reject(
                    new Error(t('inputs.errors.emailDoesnMatch').toString()),
                );
            }
            if (sameError1 || sameError2) {
                if (event.field === 'newEmail') {
                    setSameError1(false);
                }
                if (event.field === 'newEmailConfirm') {
                    setSameError2(false);
                }
                console.log('EMAIL SAME');
                return Promise.reject(
                    new Error(t('inputs.errors.accountSameEmailAlreadyExists').toString()),
                );
            }
            if (SomethingError1 || SomethingError2) {
                if (event.field === 'newEmail') {
                    setSomethingError1(false);
                }
                if (event.field === 'newEmailConfirm') {
                    setSomethingError2(false);
                }
                return Promise.reject(
                    new Error(t('inputs.errors.unknownServerError').toString()),
                );
            }
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(
                new Error(t('inputs.errors.validationServiceErrorOccurred').toString()),
            );
        }
    };

    const postFormData = async () => {
        try {
            await axios.post(
                `${EDENEX_ACCOUNT_URL}/edenex-account/api/email-change`,
                {
                    email: form.getFieldValue('newEmail'),
                },
                {
                    headers: {
                        Authorization: `Bearer ${cookie.token?.replace('Bearer ', '')}` || axios.defaults.headers['Authorization'],
                    },
                },
            );
            setNewEmail(form.getFieldValue('newEmail'));

            setShowModalEmail(false);
            setShowModalEmailConfirm(true);
            notification.info({
                message: <span
                    style={{
                        fontSize: '16px',
                        fontWeight: '700',
                        color: '#FBFBFF',
                    }}>Отправлено письмо на новую почту'</span>, // TODO: Добавить локализацию
                style: {background: '#2B2B36'},
                placement: 'topRight',
                duration: 2,
            });
            localStorage.setItem('isChangeEmail', String(true));
        } catch (e: any) {
            if (e.request.status === 405) {
                setSameError1(true);
                setSameError2(true);
            } else if (
                e.request.status === 404 ||
                e.request.status === 0 ||
                e.request.status
            ) {
                setSomethingError1(true);
                setSomethingError2(true);
            }
        }
    };

    const validateFields = async () => {
        try {
            await form.validateFields();
        } catch (err) {
        }
    };

    const handleCancelEmailConfirm = () => {
        setShowModalEmailConfirm(false);
        setBtnSubmitDisabled(true);
    };

    let hideEmail = function (email: string) {
        return email?.replace(
            /(.{2})(.*)(?=@)/,
            function (gp1: string, gp2: string, gp3: string) {
                for (let i = 0; i < gp3.length; i++) {
                    gp2 += '*';
                }
                return gp2;
            },
        );
    };

    useEffect(() => {
        if (sameError1 || sameError2 || SomethingError1 || SomethingError2) {
            form.validateFields().then();
        }
    }, [sameError1, sameError2, SomethingError1, SomethingError2]);

    return (
        <>
            <ChangeEmailModalStyled
                title={t('titles.changeEmail')}
                open={showModalEmail}
                closeIcon={
                    <IconCloseStyled
                        component={CloseIcon}
                        onClick={() => setShowModalEmail(false)}
                    />
                }
                okText={t('buttons.change').toString()}
                cancelText={t('buttons.cancel').toString()}
                width={560}
                centered
                maskStyle={{
                    backdropFilter: 'blur(9px)',
                }}
                closable={true}
                destroyOnClose={true}
                footer={[
                    <CustomSubmitButton
                        key='submit'
                        type='primary'
                        disabled={btnSubmitDisabled}
                        onClick={handleClickEmailConfirm}
                    >
                        {t('buttons.change')}
                    </CustomSubmitButton>,
                    <CancelButtonStyled
                        key='cancel'
                        className={'CancelButton'}
                        onClick={handleCancelEmailModal}
                    >
                        {t('buttons.cancel')}
                    </CancelButtonStyled>,
                ]}
            >
                <Typography.Text style={{fontSize: '16px', fontWeight: '400'}}>
                    {t('descriptions.linkNewEmailConfirmChanges')}
                </Typography.Text>
                <ChangeEmailModalFormStyled
                    form={form}
                    onValuesChange={handleFieldValueChange}
                    preserve={false}
                    name='basic'
                    autoComplete='off'
                    layout={'vertical'}
                    requiredMark={false}
                    onFieldsChange={() => setBtnSubmitDisabled(false)}
                >
                    <Form.Item
                        label={
                            <ChangeEmailModalFormInputLabelStyled>
                                {t('inputs.labels.enterNewEmail')}
                            </ChangeEmailModalFormInputLabelStyled>
                        }
                        name='newEmail'
                        rules={[{validator: checkErrors}]}
                    >
                        <Input placeholder='Email'/>
                    </Form.Item>
                    <Form.Item
                        label={
                            <ChangeEmailModalFormInputLabelStyled>
                                {t('titles.confirmNewEmail')}
                            </ChangeEmailModalFormInputLabelStyled>
                        }
                        name='newEmailConfirm'
                        rules={[{validator: checkErrors}]}
                    >
                        <Input placeholder='Email'/>
                    </Form.Item>
                </ChangeEmailModalFormStyled>
            </ChangeEmailModalStyled>

            <ChangeEmailModalStyled
                title={t('titles.confirmEmail')}
                open={showModalEmailConfirm}
                onCancel={handleCancelEmailConfirm}
                onOk={() => {
                    axios
                        .post(
                            `${EDENEX_ACCOUNT_URL}/edenex-account/api/email-change`,
                            {
                                value: form.getFieldValue('newEmail'),
                            },
                        )
                        .then(handleCancelEmailConfirm);
                }}
                okText={t('buttons.change')}
                cancelText={t('buttons.cancel')}
                width={560}
                closeIcon={<IconCloseStyled/>}
                centered
                maskStyle={{
                    backdropFilter: 'blur(9px)',
                }}
                footer={null}
            >
                <>
                    <Typography.Text style={{fontSize: '16px'}}>
                        {t('descriptions.newEmailAddress')}{' '}
                        {newEmail}{' '}
                        {t('descriptions.linkBeenSentConfirmChanges')}
                    </Typography.Text>
                    <ButtonsWrapperStyled>
                        <CloseButtonStyled onClick={handleCancelEmailConfirm}>
                            {t('buttons.close')}
                        </CloseButtonStyled>
                    </ButtonsWrapperStyled>
                </>
            </ChangeEmailModalStyled>
        </>
    );
};
export {ChangeEmailModal};

const IconCloseStyled = styled(Icon)`
    font-size: 11px;
`;

const CancelButtonStyled = styled(Button)`
    width: 180px;
`;
const CloseButtonStyled = styled(Button)`
    width: 100%;
    background: #7447f6;
    color: #fbfbff;
    border: 0;
`;
const CustomSubmitButton = styled(Button)`
    width: 60%;

    pointer-events: none;
    ${(props) =>
            props.disabled === false ? 'pointer-events: all' : 'pointer-events: none'};
`;

const ChangeEmailModalStyled = styled(Modal)`
    .ant-modal-footer {
        flex-direction: row;
        gap: 16px;
    }

    input::placeholder {
        color: #767687;
    }
`;

const ChangeEmailModalFormStyled = styled(Form)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    margin-top: 24px;
    gap: 24px;
`;

const ChangeEmailModalFormInputLabelStyled = styled.label`
    color: '#FBFBFF';
`;

const ButtonsWrapperStyled = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
    padding-top: 26px;
`;
