import styled from 'styled-components';

export const ChatListItemWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
`;

export const GradientBackground = styled.div<{ $isSell?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc(100%);
  background: ${({ $isSell }) =>
    $isSell
      ? 'linear-gradient(75.86deg, #6320E6 6.84%, #7B59FD 99.53%)'
      : 'linear-gradient(75.86deg, #088473 6.84%, #0EC6AD 107.11%)'};
  border-radius: 8px;
  z-index: 0;
`;

export const ChatListItemStyled = styled.div<{
  $isActive?: boolean;
}>`
  position: relative;
  border: 1px solid #474755;
  border-left: none;
  right: -2px;
  background-clip: padding-box;
  border-radius: 8px;
  z-index: 1;
  width: calc(100% - 2px);
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 0.7fr 0.7fr 0.45fr 0.6fr 0.05fr;

  padding: 20px;
  transition: 0.5s;

  &:hover {
    background-color: #2b2b36;
  }
  &:active {
    background-color: #181827;
  }

  background: ${({ $isActive }) => ($isActive ? '#2B2B36' : '#1c1c27')};
`;

export const ChatListItemContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: start;
`;

export const ChatListItemCompanyStyled = styled.div`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 12px;
`;

export const ChatListItemTypeStyled = styled.div`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: left;
  color: #fbfbff;
`;

export const ChatListItemPairStyled = styled.div`
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  color: #fbfbff;
`;

export const ChatListItemCompanyTitleStyled = styled.div`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #fbfbff;
`;

export const GavelStyled = styled.img`
  height: 14px;
  width: 14px;
  cursor: pointer;
`;
