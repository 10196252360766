import React, {useEffect, useState} from 'react';
import {Menu, Typography} from 'antd';
import {SIDEBAR_COMPONENTS as Components} from './_styles';
import './styles.css';
import {NavLink, useLocation} from 'react-router-dom';
import {SideBarLogo, SidebarLogoSmall} from '../../../../../assets/img/logo/logo';
import styled, {css} from 'styled-components';
import {DropdownIconSimpleColor} from '../../../../../assets/img/icons/icon';
import Icon, { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {useGetSideBarElements} from './useGetSideBarElements';
import {ACCOUNT_URL, BASE_MAIN_URL} from '../../../../../packages/keycloak-client/constants';
import {useResize} from '../../../../../hooks/useResize';
import {useChatListState} from "../../../../../pages/chat-list/state/useChatListState";

const Sidebar = (): JSX.Element => {
    let loc = useLocation();
    const isOpenChat = useChatListState((state) => state.isOpenChat);
    const [collapsed, setCollapsed] = useState(false);
    // const breakpoint = Grid.useBreakpoint();
    const {isScreen991} = useResize();
    const {
        DrawSideBarMenuElement,
        // BOTTOM_SIDEBAR_ELEMENTS,
        SIDEBAR_CATEGORIES,
    } = useGetSideBarElements();

    const toggleCollapseIfIsChatOpened = () => {
        if (isOpenChat) {
            setCollapsed(true);
        } else {
            setCollapsed(false);
        }
    }

    useEffect(() => {
        isScreen991 ? setCollapsed(false) : setCollapsed(true);
    }, [isScreen991]);

    useEffect(() => {
        toggleCollapseIfIsChatOpened()
    }, [isOpenChat])

    return (
        <SidebarWrapperStyled
            onClick={() => {
                setCollapsed(true);
            }}
            $isFixed={!isScreen991 && !collapsed}
        >
            <Components.CustomSider
                width={240}
                collapsible
                collapsed={collapsed}
                // onCollapse={(value) => {
                //   setCollapsed(value);
                // }}
                onClick={(event: any) => {
                    event.stopPropagation();
                }}
                // collapsedWidth={isMobile ? 0 : 80}
                breakpoint="lg"
                defaultCollapsed={!collapsed}
                trigger={null}
                theme={'dark'}
                $isScreen991={isScreen991}
            >
                <SideBarContentContainer>
                    <LogoContainer $collapsed={collapsed}>
                        <a href={`/`}>
                            <LogoContainerIcon $collapsed={collapsed}>
                                <Icon component={collapsed ? SidebarLogoSmall : SideBarLogo}/>
                            </LogoContainerIcon>
                        </a>
                        <LogoContainerArrow
                            $collapsed={collapsed}
                            onClick={(event) => {
                                event.stopPropagation();
                                setCollapsed(!collapsed);
                            }}
                        >
                            {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                        </LogoContainerArrow>
                    </LogoContainer>

                    <CommonMenu>
                        {SIDEBAR_CATEGORIES.map((item, index) => {
                            return (
                                <ContainerCategoriesStyled key={index}>
                                    <TypographyStyled>{item.name}</TypographyStyled>
                                    <MenuStyled
                                        mode="inline"
                                        selectedKeys={[loc.pathname]}
                                        defaultOpenKeys={[loc.pathname]}
                                        style={{borderInlineEnd: 'unset'}}
                                        expandIcon={() => DropdownIconSimpleColor()}
                                        items={item.elements.map((item, idx) => {
                                            const isSelected = loc.pathname.includes(item.pathKey);
                                            return {
                                                key: item.pathKey,
                                                label: (
                                                    <React.Fragment key={idx}>
                                                        {item.pathKey.includes(BASE_MAIN_URL) &&
                                                        BASE_MAIN_URL !== '' ? (
                                                            <a href={item.pathKey}>
                                                                {DrawSideBarMenuElement(
                                                                    item.title,
                                                                    () => {
                                                                        if (!!item.iconComponent) {
                                                                            return item.iconComponent(
                                                                                isSelected ? '#FBFBFF' : '#767687'
                                                                            );
                                                                        } else return <></>;
                                                                    },
                                                                    isSelected
                                                                )}
                                                            </a>
                                                        ) : (
                                                            <NavLinkStyled
                                                                to={item.pathKey}
                                                                replace={
                                                                    item.pathKey.includes(BASE_MAIN_URL) &&
                                                                    BASE_MAIN_URL !== ''
                                                                }
                                                            >
                                                                {DrawSideBarMenuElement(
                                                                    item.title,
                                                                    () => {
                                                                        if (!!item.iconComponent) {
                                                                            return item.iconComponent(
                                                                                isSelected ? '#FBFBFF' : '#767687'
                                                                            );
                                                                        } else return <></>;
                                                                    },
                                                                    isSelected
                                                                )}
                                                            </NavLinkStyled>
                                                        )}
                                                    </React.Fragment>
                                                ),
                                                children: item.childerns
                                                    ? item.childerns.map((item): ItemType => {
                                                        const isSelected = loc.pathname.includes(
                                                            item.pathKey
                                                        );
                                                        return {
                                                            key: item.pathKey,
                                                            label: (
                                                                <>
                                                                    <NavLinkStyled to={item.pathKey}>
                                                                        <ContainerStyled>
                                                                            {DrawSideBarMenuElement(
                                                                                item.title,
                                                                                () => {
                                                                                    if (!!item?.iconComponent) {
                                                                                        return item?.iconComponent(
                                                                                            isSelected ? '' : '#767687'
                                                                                        );
                                                                                    } else return <></>;
                                                                                },
                                                                                isSelected,
                                                                                true
                                                                            )}
                                                                        </ContainerStyled>
                                                                    </NavLinkStyled>
                                                                </>
                                                            ),
                                                            onClick: () => {
                                                            },
                                                        };
                                                    })
                                                    : undefined,
                                            };
                                        })}
                                    />
                                </ContainerCategoriesStyled>
                            );
                        })}
                        {/*<BottomMenu>*/}
                        {/*  <LocaleSwitcher onClick={() => {*/}
                        {/*    // const langs = i18n.languages;*/}
                        {/*    const langs = ['en', 'ru'];*/}
                        {/*    // TODO: Доработать под большее количество языков*/}
                        {/*    if (i18n.language === 'en') {*/}
                        {/*      i18n.changeLanguage(langs[1]);*/}
                        {/*    } else {*/}
                        {/*      i18n.changeLanguage(langs[0]);*/}
                        {/*    }*/}
                        {/*  }}>*/}
                        {/*    <LocaleSwitcherCurrLang>*/}
                        {/*      {i18n.language.toUpperCase()}*/}
                        {/*    </LocaleSwitcherCurrLang>*/}
                        {/*    {!collapsed && <LocaleSwitcherLang>{t('sidebar.lang')}</LocaleSwitcherLang>}*/}
                        {/*  </LocaleSwitcher>*/}
                        {/*  <Menu*/}
                        {/*    mode='inline'*/}
                        {/*    selectedKeys={[loc.pathname]}*/}
                        {/*    defaultOpenKeys={[loc.pathname]}*/}
                        {/*    style={{ borderInlineEnd: 'unset' }}*/}
                        {/*    expandIcon={() => DropdownIconSimpleColor()}*/}
                        {/*    items={BOTTOM_SIDEBAR_ELEMENTS.map((item) => {*/}
                        {/*      return {*/}
                        {/*        key: item.pathKey,*/}
                        {/*        label: (*/}
                        {/*          <>*/}
                        {/*            {item.onClick ? (*/}
                        {/*              <div*/}
                        {/*                style={{*/}
                        {/*                  paddingLeft: 10,*/}
                        {/*                  height: 40,*/}
                        {/*                  display: 'flex',*/}
                        {/*                  cursor: 'pointer',*/}
                        {/*                }}*/}
                        {/*                onClick={item.onClick}*/}
                        {/*              >*/}
                        {/*                {DrawSideBarMenuElement(*/}
                        {/*                  item.title,*/}
                        {/*                  () => {*/}
                        {/*                    if (!!item.iconComponent) {*/}
                        {/*                      return item.iconComponent('#767687');*/}
                        {/*                    } else return <></>;*/}
                        {/*                  },*/}
                        {/*                  false,*/}
                        {/*                )}*/}
                        {/*              </div>*/}
                        {/*            ) : (*/}
                        {/*              <NavLinkStyled to={item.pathKey}>*/}
                        {/*                <div*/}
                        {/*                  style={{ paddingLeft: 10, height: 40, display: 'flex' }}*/}
                        {/*                >*/}
                        {/*                  {DrawSideBarMenuElement(*/}
                        {/*                    item.title,*/}
                        {/*                    () => {*/}
                        {/*                      if (!!item.iconComponent) {*/}
                        {/*                        return item.iconComponent('#767687');*/}
                        {/*                      } else return <></>;*/}
                        {/*                    },*/}
                        {/*                    false,*/}
                        {/*                  )}*/}
                        {/*                </div>*/}
                        {/*              </NavLinkStyled>*/}
                        {/*            )}*/}
                        {/*          </>*/}
                        {/*        ),*/}
                        {/*      };*/}
                        {/*    })}*/}
                        {/*  />*/}
                        {/*</BottomMenu>*/}
                    </CommonMenu>
                </SideBarContentContainer>
            </Components.CustomSider>
        </SidebarWrapperStyled>
    );
};

export {Sidebar};

const SideBarContentContainer = styled.div`
    padding-top: 32px;
    //background: linear-gradient(80.47deg, #18181f 6.62%, #2d2d3a 148.62%);
    background: #14141f;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.24),
    0 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-right: 1px solid #413d5080;
    display: flex;
    flex-direction: column;
    gap: 36px;
    height: 100%;
`;

const LogoContainer = styled.div<{ $collapsed?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 24px;
    justify-content: space-between;
    padding-right: 30px;
    ${(props) =>
            props.$collapsed &&
            css`
                justify-content: center;
                padding-right: 0;
            `}
}
`;

const LogoContainerIcon = styled.div<{ $collapsed?: boolean }>`
    display: flex;
    justify-content: center;
    ${(props) =>
            !props.$collapsed &&
            css`
                padding-left: 16px;
            `}
`;

const LogoContainerArrow = styled.div<{ $collapsed?: boolean }>`
    color: #fff;
    font-size: 16px;
    &:hover {
        cursor: pointer;
    }

    ${(props) =>
            props.$collapsed &&
            css`
                padding-left: 10px;
            `}
`;

const CommonMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const BottomMenu = styled.div`
    border-top: 1px solid #413d5080;
    display: flex;
    flex-direction: column;
    margin-bottom: 44px;
    justify-items: flex-end;
    justify-content: flex-end;
    padding-top: 28px;
`;

const LocaleSwitcher = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding-left: 36px;
    height: 30px;
`;

const LocaleSwitcherLang = styled.div`
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #767687;
    width: -webkit-fill-available;
`;

const LocaleSwitcherCurrLang = styled.div`
    color: #767687;
    margin-right: 8px;
`;
const MenuStyled = styled(Menu)`
    .ant-menu-submenu {
        padding-bottom: 0 !important;
    }

    .ant-menu-item {
        padding-top: 0 !important;
    }
`;
const NavLinkStyled = styled(NavLink)`
    padding-left: 0;
`;
const ContainerStyled = styled.div`
    padding-left: 10px;
`;
const SidebarWrapperStyled = styled.div<{ $isFixed?: boolean }>`
    ${({$isFixed}) =>
            $isFixed
                    ? css`
                        position: fixed;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        z-index: 900;
                    `
                    : css`
                        box-sizing: content-box;
                    `}
`;
const TypographyStyled = styled(Typography.Text)`
    padding-left: 26px;
    font-size: 12px;
    line-height: 15px;
`;
const ContainerCategoriesStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 11;
`;
