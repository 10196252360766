import styled from 'styled-components';
import {useEffect, useState} from 'react';
import NotFoundPage from '../errors/not-found';
import {useMessagesState} from '../../components/ChatModule/states/useMessagesState';
import {useChatTokensState} from 'state/useChatTokensState';
import {useCookiesCustom} from 'hooks/useCookieCustom';
import ChatRefereeWrapper from "../../components/ChatWrappers/ChatRefereeWrapper";
import {getCookieByName} from "../../shared/helpers/controlCookies";

enum QueryParamsReferee {
    TOKEN = 'token',
    OFFER_DEAL_ID = 'offer_deal_id',
}

export const RefereePage = () => {
    const searchMessages = useMessagesState((state) => state.searchMessages);
    const getHubChatToken = useChatTokensState((state) => state.getHubChatToken);
    const [IsErrorParams, setIsErrorParams] = useState(false);
    const {cookie, setCookie, removeCookie} = useCookiesCustom();
    const token =
        new URLSearchParams(window.location.search).get(QueryParamsReferee.TOKEN) ||
        localStorage.getItem('hub_token');
    const offer_deal_id =
        new URLSearchParams(window.location.search).get(
            QueryParamsReferee.OFFER_DEAL_ID
        ) || localStorage.getItem('offer_deal_id');

    useEffect(() => {
        if (!token || !offer_deal_id) {
            setIsErrorParams(true);
        } else if (token && offer_deal_id) {
            setCookie('hubChatToken', token);
            searchMessages({
                entity_type: 'offer_deal',
                entity_id: offer_deal_id,
                auth_token: `Bearer ${getCookieByName('hubChatToken')?.replace(
                    'Bearer',
                    ''
                )}`,
                client_uid: undefined
            }).then();
        }

        return () => {
            removeCookie('hubChatToken');
        };
    }, []);

    useEffect(() => {
        const updateChatTokenWorker = new Worker(
            new URL('../../workers/chatRefreshWorker', import.meta.url)
        );

        if (token) {
            getHubChatToken().then();
        }

        updateChatTokenWorker.onmessage = () => {
            if (cookie['hubChatToken']) {
                getHubChatToken().then();
            }
        };

        return () => {
            updateChatTokenWorker.terminate();
        };
    }, [token]);

    if (IsErrorParams) {
        return <NotFoundPage/>;
    }
    return (
        <ChatContainerStyled>
            <ChatRefereeWrapper offerDealId={offer_deal_id}/>
        </ChatContainerStyled>
    );
};

const ChatContainerStyled = styled.div`
    max-width: 800px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border-left: 1px solid #413d5080;
    border-right: 1px solid #413d5080;
`;
