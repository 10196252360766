import {useChatTokensState} from "../../state/useChatTokensState";
import {useMessagesState} from "../ChatModule/states/useMessagesState";
import {useCookiesCustom} from "../../hooks/useCookieCustom";
import {Centrifuge} from "centrifuge";
import {WS_URL} from "../../packages/keycloak-client/constants";
import {useEffect} from "react";
import {deleteCookieByName} from "../../shared/helpers/controlCookies";
import ChatModule from "../ChatModule/ChatModule";
import {calcAuthToken} from "../ChatModule/helpers/calcAuthToken";

type TChatRefereeWrapper = {
    offerDealId: string | null
}

const ChatRefereeWrapper = ({offerDealId}: TChatRefereeWrapper) => {
    const wssChannels = useChatTokensState((state) => state.wssChannels);
    const getAdminWSChatToken = useMessagesState((state) => state.getAdminWSChatToken);
    const searchMessages = useMessagesState((state) => state.searchMessages);
    const totalMessages = useMessagesState((state) => state.totalMessages);
    const currentPage = useMessagesState((state) => state.currentPage);
    const isFetchingMessages = useMessagesState((state) => state.isFetchingMessages);
    const messageLimit = useMessagesState((state) => state.messageLimit);
    const setIsFetchingMessages = useMessagesState((state) => state.setIsFetchingMessages);
    const addMessageToMessages = useMessagesState((state) => state.addMessageToMessages);
    const messages = useMessagesState((state) => state.messages);
    const createAttachment = useMessagesState((state) => state.createAttachment);
    const addMessage = useMessagesState((state) => state.addMessage);
    const changeIsUploadInAttachment = useMessagesState((state) => state.changeIsUploadInAttachment);
    const uploadAttachmentToMinio = useMessagesState((state) => state.uploadAttachmentToMinio);

    const {cookie} = useCookiesCustom();

    const centrifugeWithHubChatToken = new Centrifuge(WS_URL, {
        token: cookie['hubChatToken'],
        getToken: async () => {
            getAdminWSChatToken().then();
            return cookie['hubChatToken'];
        }
    });

    async function subscribeToChannel({channelName}: { channelName: string }) {
        const chatUserSub = centrifugeWithHubChatToken.newSubscription(channelName);
        chatUserSub.on('publication', function (pubData) {
            const {data} = pubData;
            if (data.type === 'new_message') {
                addMessageToMessages(data?.message);
            }
        }).subscribe();
    }

    useEffect(() => {
        getAdminWSChatToken().then(async () => {
            if (!!wssChannels.length) {
                for (const channelName of wssChannels) {
                    await subscribeToChannel({channelName})
                }
            }
        }).finally(() => {
            centrifugeWithHubChatToken.connect();
        });

        return () => {
            centrifugeWithHubChatToken.disconnect();
            deleteCookieByName('hubChatToken');
        }
    }, [])


    useEffect(() => {
        if ((messageLimit * currentPage < totalMessages || currentPage === 1)) {
            searchMessages({
                entity_type: 'offer_deal',
                entity_id: offerDealId ? offerDealId : '',
                auth_token: calcAuthToken({chatType: 'admin'}),
                client_uid: undefined
            }).then()

        }
    }, [currentPage, totalMessages, messageLimit]);

    return (
        <ChatModule
            isShowTimer={false}
            isClosableChat={false}
            currentChat={{}}
            isReferee={false}
            setIsOpenChat={() => {
            }}
            opponent={undefined}
            currentPage={currentPage}
            setIsFetchingMessages={setIsFetchingMessages}
            searchMessages={searchMessages}
            messageLimit={messageLimit}
            totalMessages={totalMessages}
            isFetchingMessages={isFetchingMessages}
            messages={messages}
            addMessage={addMessage}
            changeIsUploadInAttachment={changeIsUploadInAttachment}
            createAttachment={createAttachment}
            uploadAttachmentToMinio={uploadAttachmentToMinio}
            calcEntityId={() => offerDealId ? offerDealId : ''}
            entity_type={'offer_deal'}
            client_uid={undefined}
            chatType={'admin'}
            typeTabs={''}
        />
    )
}

export default ChatRefereeWrapper;
