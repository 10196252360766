import {styled} from "styled-components";

export const ChatListItemOfferStatusStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const ChatListItemOfferStatusRightStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const ChatListItemOfferStatusRightTitleStyled = styled.div`
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #FFFFFF;
`;

export const ChatListItemOfferStatusRightStatus = styled.div`
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #767687;
`;
