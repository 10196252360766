import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useAuthState } from '../../../../../../../state/useAuthState';

const { Text } = Typography;
const UserBalance: FC = () => {
  const { t } = useTranslation();
  const userAPI = useAuthState((state) => state.userAPI);
  const [total, setTotal] = useState(userAPI?.account?.total || 0);

  const [visible, setVisible] = useState(true);

  const getEye = () => (visible ? <EyeIcon /> : <EyeHiddenIcon />);

  const showBalance = () => (visible ? total + ' USDT' : '**********');

  const handleClick = () => setVisible((prevState) => !prevState);

  useEffect(() => {
    setTotal(Math.trunc(userAPI?.account?.total * 100) / 100 || 0);
  }, [userAPI?.account?.total]);

  return (
    <UserBalanceWrapper>
      <BalanceHeader>
        <Balance>{showBalance()}</Balance>
        <EyeButton onClick={handleClick}>{getEye()}</EyeButton>
      </BalanceHeader>
      <BalanceHeaderText>{t('header.balance')}</BalanceHeaderText>
    </UserBalanceWrapper>
  );
};

export default UserBalance;

const UserBalanceWrapper = styled.div`
  font-family: 'Inter', sans-serif;

  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const BalanceHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BalanceHeaderText = styled(Text)`
  font-size: 12px;
  color: #ffffff80;
`;

const Balance = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.3;
`;

const EyeIcon = styled(EyeOutlined)`
  line-height: 0;
  color: white;
`;

const EyeHiddenIcon = styled(EyeInvisibleOutlined)`
  line-height: 0;
  color: white;
`;

const EyeButton = styled.div`
  align-self: baseline;
  font-size: 12px !important;
  cursor: pointer;
  line-height: 0 !important;
  & svg {
    width: 12px;
    height: 12px;
  }
`;
