import { styled } from 'styled-components';
import { Tabs } from 'antd';

export const TabLabelStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ChatListFiltersStyled = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const TabsStyled = styled(Tabs)`
  .ant-tabs-tab {
    display: flex;
    color: white !important;
    .ant-tabs-tab-btn {
      div {
        align-items: center;
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: #1677ff;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1677ff !important;
  }

  .ant-tabs-nav::before {
    border-bottom: none;
    background-color: white;
  }

  .ant-badge-status-cyan {
    background: #0ec6ad;
  }

  &&& .ant-badge {
    margin-left: 4px;
  }
`;
