import React, {useEffect, useMemo, useRef, useState} from 'react';
import {ChatMessage} from '../message';
import {TimeMark} from '../time-mark';
import {
    IChatNotificationMessage,
    IChatOrderUpdatedMessage,
    IChatTimemarkMessage,
    IUloadChatMessage,
} from '../../../interfaces/entity';
import styled from 'styled-components';
import {useCookiesCustom} from "../../../../../hooks/useCookieCustom";

type TChatListMessages = {
    messages: any[]
}

const ChatListMessages = ({messages}: TChatListMessages) => {
    const {cookie} = useCookiesCustom();

    const lastMessageRef = useRef<any>(null);

    const [messagesWithTimemark, setMessagesWithTimemark] = useState<
        (
            | IUloadChatMessage
            | IChatNotificationMessage
            | IChatOrderUpdatedMessage
            | IChatTimemarkMessage
            )[]
    >([]);

    const integrateTimemarkInMessages = (
        messages: (
            | IUloadChatMessage
            | IChatNotificationMessage
            | IChatOrderUpdatedMessage
            )[]
    ) => {
        const newArray: any[] = [];
        for (let i = messages.length - 1; i >= 0; i--) {
            newArray.push(messages[i]);
            if (
                i !== 0 &&
                new Date(messages[i].created_at).getDate() !==
                new Date(messages[i - 1].created_at).getDate()
            ) {
                newArray.push({
                    type: 'timemark',
                    date: new Date(messages[i].created_at),
                });
            }
        }
        setMessagesWithTimemark(newArray.reverse());
    };

    const getMessageComponent = (messageItem: any) => {
        if (messageItem.type === 'timemark') {
            return (
                <TimemarkWrapper key={messageItem?.id}>
                    <TimeMark bigSize date={messageItem.date || new Date()}/>
                </TimemarkWrapper>
            );
        }
        if (!messageItem.is_system) {
            return (
                <ChatMessage
                    key={messageItem.id}
                    date={new Date(messageItem.created_at)}
                    float={
                        cookie['uid'] === messageItem?.sender_id
                            ? 'right'
                            : 'left'
                    }
                    messageData={messageItem}
                    attachment={messageItem.attachment}
                    message={messageItem.text as string}
                    participant={messageItem?.participant}
                    // TODO: Добавить Nickname если есть
                    senderNickName={
                        // buyerId === messageItem?.sender_id
                        //     ? buyerInfo?.nickname
                        //     : sellerInfo?.nickname
                        ''
                    }
                />
            );
        }

        // TODO: Подумать)

        // switch (messageItem.type) {
        //     case 'user':
        //         return (
        //             <ChatMessage
        //                 key={messageItem.id}
        //                 color="default"
        //                 date={new Date(messageItem.created_at)}
        //                 float={
        //                     uid === messageItem?.sender_id
        //                         ? 'right'
        //                         : 'left'
        //                 }
        //                 attachments={messageItem.attachments}
        //                 message={messageItem.text as string}
        //                 senderNickName={
        //                     // buyerId === messageItem?.sender_id
        //                     //     ? buyerInfo?.nickname
        //                     //     : sellerInfo?.nickname
        //                     ''
        //                 }
        //             />
        //         );
        //     case 'system':
        //         return (
        //             <React.Fragment key={messageItem?.id}>
        //                 <ChatMessageBadge
        //                     date={new Date(messageItem.created_at)}
        //                     content={String(
        //                         t(`chatModule.dealStatus.${messageItem.data.dirty_status}`)
        //                     )}
        //                     status={messageItem.data.dirty_status}
        //                 />
        //                 {messageItem.data.dirty_status === 'respond' && (
        //                     <>
        //                         <ChatMessageBadge
        //                             content={String(
        //                                 t(`chatModule.dealStatus.${messageItem.data.dirty_status}2`)
        //                             )}
        //                             status={messageItem.data.dirty_status}
        //                         />
        //                         <ChatMessageAlert header={t(`chatModule.warning.title`)}>
        //                             {t(`chatModule.warning.text`)}
        //                         </ChatMessageAlert>
        //                     </>
        //                 )}
        //             </React.Fragment>
        //         );
    };

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [messagesWithTimemark]);

    useEffect(() => {
        integrateTimemarkInMessages(messages);
    }, [messages]);

    const renderMessages = useMemo(() => {
        if (!!messagesWithTimemark?.length) {
            return (
                <>
                    {!!messagesWithTimemark?.length &&
                        messagesWithTimemark.map((messageItem) =>
                            getMessageComponent(messageItem)
                        )}
                </>
            )
        } else {
            return (
                <EmptyMessages>
                    No messages
                </EmptyMessages>
            )
        }
    }, [messagesWithTimemark])

    return (
        <MessagesContainer>
            {renderMessages}
            <div
                ref={lastMessageRef}
            />
        </MessagesContainer>
    );
};

const EmptyMessages = styled.div`
    background: #474755;
    padding: 4px 12px 4px 12px;
    border-radius: 4px;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    color: #FBFBFF;
    display: flex;
    justify-self: center;
    align-self: center;
    height: 100%;
`;

const MessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgb(255, 255, 255);
        background: #F3F3F8;
        border-radius: 4px;
    }
`;

const TimemarkWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px 0;
`;

export {ChatListMessages};
