import React, { useEffect } from 'react';
import './NotificationsAsideBell.css';
import { BellOutlined } from '@ant-design/icons';
import { DropdownStyled } from './NotificationAsideBell.style';
import { NotificationAside } from '../NotificationAside/NotificationAside';
import { useNotificationAsideState } from '../../model/useNotificationAsideState';
import { getCookieByName } from '../../../../shared/helpers/controlCookies';
import jwtDecode from 'jwt-decode';
import { WS_URL } from '../../../../packages/keycloak-client/constants';
import { Centrifuge } from 'centrifuge';
import { Badge } from 'antd';

export const NotificationsAsideBell = () => {

  const totalCount = useNotificationAsideState((state) => state.totalNotificationsCount)
  const notifications = useNotificationAsideState((state) => state.notifications)
  const setNotifications = useNotificationAsideState((state) => state.setNotifications)
  const setTotalNotificationsCount = useNotificationAsideState((state) => state.setTotalNotificationsCount)

  const centrifuge = new Centrifuge(`${WS_URL}`);

  const connectToNotificationWs = () => {
    try {
      const decodedToken: any = jwtDecode(getCookieByName('token') || '');
      if (!!decodedToken) {
        const sub = centrifuge.newSubscription(decodedToken?.sub);
        sub.on('publication', function (ctx: any) {
          setNotifications()
          setTotalNotificationsCount()
          // пригодится позже для добавления popup уведов
          // if (ctx?.data?.data?.model === 'popup_notifications') {
          //   getPopupNotificationSearch(ctx?.data?.data?.id).then();
          // }
        });
        sub.subscribe();
        centrifuge.connect();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    connectToNotificationWs();
    setTotalNotificationsCount()
    return () => {
      centrifuge.disconnect()
    };
  }, [notifications.length]);

  return (
    <DropdownStyled
      trigger={['click']}
      className={'ant-dropdown-notifications-custom'}
      dropdownRender={() => <NotificationAside />}
    >
      <Badge
        count={totalCount}
        size={'default'}
        color={'linear-gradient(45deg, #088473 0%, #12BFA8 100%)'}
        showZero={true}
        offset={[7, 0]}
      >
        <BellOutlined />
      </Badge>
    </DropdownStyled>
  )
}
