import styled from 'styled-components';
import { ConfigProvider, Dropdown, MenuProps } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import { t } from 'i18next';
interface LinksDropdownProps {
  itemWidth?: number;
  items: MenuProps['items'];
}
export const LinksDropdown = (props: LinksDropdownProps) => {
  const { itemWidth = 280, items = [] } = props;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#202124',
          fontFamily: 'Inter, sans-serif',
        },
        components: {
          Menu: {
            radiusItem: 0,
            colorItemBg: '',
            colorItemBgHover: '#383844',
            colorItemText: '#767687',
            colorItemTextHover: '#FBFBFF',
            colorItemTextSelected: '#FBFBFF',
            marginXXS: 0,
            marginXS: 0,
          },
          Typography: {
            colorLink: '#404348', //TODO Поменять как будет
            colorLinkHover: '#404348',
            colorLinkActive: '#404348',
            colorPrimary: '#FBFBFF',
            colorText: '#FBFBFF',
            colorTextHeading: '#FBFBFF',
          },
          Dropdown: {
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            colorBgElevated: '#383844',
            colorText: '#FBFBFF',
            controlHeight: 48,
            boxShadowSecondary: 'transparent',
            paddingXXS: 0,
            paddingXS: 0,
            colorBgBase: '#2B2B36',
            controlItemBgHover: '#20202E',
            controlItemBgActive: '#20202E',
            controlItemBgActiveHover: '#20202E',
          },
        },
      }}
    >
      <DropdownStyled
        menu={{
          items: items?.map((item) => {
            if (item) {
              return {
                ...item,
                style: {
                  padding: '11px 16px',
                  minWidth: itemWidth,
                  marginBottom: '1px',
                  borderRadius: '4px',
                  color: '#fff',
                  ...item.style,
                },
              };
            } else {
              return item;
            }
          }),
          selectable: true,
          defaultSelectedKeys: ['Platform'],
        }}
      >
        <ContainerStyled>
          <AppstoreOutlined style={{ fontSize: 20, color: '#ffffff' }} />
        </ContainerStyled>
      </DropdownStyled>
    </ConfigProvider>
  );
};

const DropdownStyled = styled(Dropdown)``;
const ContainerStyled = styled.div`
  cursor: pointer;
  display: flex;
  padding: 10px;
  border-radius: 8px;
  transition: 0.1s;
  &:hover {
    background: #ffffff1a;
  }
`;
