import styled from 'styled-components';

export const ChatListHeaderTitleStyled = styled.h1`
  font-family: SF Pro Text, sans-serif;
  font-size: 38px;
  font-weight: 600;
  line-height: 46px;
  text-align: left;
  color: #ffffff;
`;

export const ChatListHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  padding-top: 12px;
  margin-bottom: 20px;
  margin-top: 20px;
`;
