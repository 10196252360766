import {
  ChatListItemStyled,
  ChatListItemWrapper,
  GradientBackground,
} from './assets/styles';
import ChatListItemMessageCounter from './components/MessageCounter/ChatListItemMessageCounter';
import ChatListItemOfferStatus from './components/OfferStatus/ChatListItemOfferStatus';
import ChatListItemOfferStep from './components/OfferStep/ChatListItemOfferStep';
import ChatListItemTypePair from './components/TypePair/ChatListItemTypePair';
import ChatListItemCompany from './components/Company/ChatListItemCompany';
import { TTabs, useChatListState } from '../../../../state/useChatListState';
import { IChat } from '../../../../../../interfaces';
import { FINMS_URL } from '../../../../../../packages/keycloak-client/constants';
import { useNavigate } from 'react-router-dom';

type ChatListItem = {
  item: IChat;
  typeTabs: TTabs;
};

const ChatListItem = ({ item, typeTabs }: ChatListItem) => {
  const navigate = useNavigate();

  const setOpenChatId = useChatListState((state) => state.setOpenChatId);
  const openChatId = useChatListState((state) => state.openChatId);
  const setIsOpenChat = useChatListState((state) => state.setIsOpenChat);

  const setChatToStore = () => {
    setOpenChatId(item.id);
    setIsOpenChat(true);
  };
  const handleOpenChat = () => {
    if (typeTabs === 'personal') {
      if (item.entity_type === 'offer_deal') {
        navigate(`/deals/${item.offer_deal_id}`);
      } else {
        setChatToStore();
      }
    } else {
      if (item.entity_type === 'offer_deal') {
        window.location.href = `${FINMS_URL}/offers/request/${item.offer_deal_id}`;
      } else {
        setChatToStore();
      }
    }
    // if (
    //   typeTabs === 'personal' ||
    //   (typeTabs === 'corporate' && item.entity_type === 'offer')
    // ) {
    //   setOpenChatId(item.id);
    //   setIsOpenChat(true);
    // } else if (typeTabs === 'corporate') {
    //   window.location.href = `${FINMS_URL}/offers/request/byOffer/${item.offer?.id}`;
    // }
  };
  return (
    <>
      <ChatListItemWrapper onClick={handleOpenChat}>
        <GradientBackground $isSell={typeTabs === 'corporate' ? !item.offer?.is_buying_crypto : item.offer?.is_buying_crypto} />
        <ChatListItemStyled $isActive={item.id === openChatId}>
          <ChatListItemTypePair
            typeTabs={typeTabs}
            isRefereeInChat={item.has_referee}
            pair={`${item.offer?.crypto_payment_system_currency?.currency_key}-${item.offer?.cash_payment_system_currency?.currency_key}`}
            isSellingCrypto={item.offer?.is_buying_crypto}
          />
          <ChatListItemCompany
            companyName={item.offer?.exchange_point?.name}
            clientNickname={item?.offer_deal?.nickname ?? ''}
            typeTabs={typeTabs}
          />
          <ChatListItemOfferStatus status={'active' === item.offer?.status} />

          <ChatListItemOfferStep
            step={item.offer_deal?.status}
            id={item?.offer_deal?.name}
            closedAt={item.closed_at}
          />

          <ChatListItemMessageCounter
            count={item?.unread_count}
            isShow={!!item?.unread_count}
          />
        </ChatListItemStyled>
      </ChatListItemWrapper>
    </>
  );
};

export default ChatListItem;
