import {devtools} from 'zustand/middleware';
import create from 'zustand';
import {axios} from '../shared/exios';
import {CHAT_URL, EXCHANGE_MAP_API_URL, FINMS_URL_API, HUB_API_URL} from '../packages/keycloak-client/constants';
import {getCookieByName, setCookieByName} from '../shared/helpers/controlCookies';
import {produce} from "immer";

type TChatTokensState = {
    wssChannels: string[];
    getPhexChatToken: () => Promise<void>;
    getPartnerChatToken: () => Promise<void>;
    getHubChatToken: () => Promise<void>;
    getWSChatToken: () => Promise<void>;
};

export const useChatTokensState = create<TChatTokensState>()(
    devtools(
        (set, get) => ({
            wssChannels: [],
            getWSChatToken: async () => {
                try {
                    const res = await fetch(`${CHAT_URL}/server/chat/api/ws/token`, {
                        method: 'get',
                        headers: {
                            Authorization: `Bearer ${getCookieByName('partnerChatToken')?.replace(
                                'Bearer',
                                ''
                            ) || getCookieByName('phexChatToken')?.replace(
                                'Bearer',
                                ''
                            )}`
                        }
                    })
                    // Parse the JSON response
                    const data = await res.json();
                    setCookieByName('wsChatToken', data?.token);
                    set(
                        produce((draft) => {
                            draft.wssChannels = data?.channels;
                        })
                    )

                } catch (e) {
                    console.error('getWSChatToken error', e);
                }
            },
            getPhexChatToken: async () => {
                try {
                    const res = await axios.get(
                        `${FINMS_URL_API}/server/api/chats/get-token`
                    );
                    console.log('getPhexChatToken res?.data?.data', res?.data?.data)
                    if (res?.data?.data?.access_token) {
                        setCookieByName('phexChatToken', res?.data?.data?.access_token);
                    }
                } catch (e) {
                    // @ts-expect-error
                    console.error('getPhexChatToken error:', e?.response?.data?.message);
                }
            },
            getPartnerChatToken: async () => {
                try {
                    const res = await axios.get(
                        `${EXCHANGE_MAP_API_URL}/partner/api/chats/get-token`
                    );
                    console.log('getPartnerChatToken res?.data?.data', res?.data?.data)
                    if (res?.data?.data?.access_token) {
                        setCookieByName('partnerChatToken', res?.data?.data?.access_token);
                    }
                } catch (e) {
                    console.error('getPartnerChatToken error:', e);
                }
            },
            getHubChatToken: async () => {
                try {
                    if (getCookieByName('hubChatToken')) {
                        // TODO: Должен использоваться токен из query (ключ пока не придумали)
                        const res = await axios.get(`${HUB_API_URL}/api/chats/get-token`, {
                            headers: {
                                Authorization: `Bearer ${getCookieByName('hubChatToken')?.replace(
                                    'Bearer',
                                    ''
                                )}`
                            }
                        });

                        if (res?.data?.data?.access_token) {
                            setCookieByName('hubChatToken', res?.data?.data?.access_token);
                        }
                    }
                } catch (e) {
                    // @ts-expect-error
                    console.error('getHubChatToken error:', e?.response?.data?.message);
                }
            },
        }),
        {
            anonymousActionType: 'useChatTokensState action',
            name: 'useChatTokensState',
        }
    )
);
