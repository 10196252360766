import {ChatListLeftStyled, ChatListRightStyled, ChatListStyled,} from './assets/styles';
import ChatListHeader from './components/ChatListHeader/ChatListHeader';
import ChatListItems from './components/ChatListItems/ChatListItems';
// import ChatModule from './components/ChatModule/ChatModule';
import {TTabs, useChatListState} from './state/useChatListState';
import {useEffect} from 'react';
import {getCookieByName} from '../../shared/helpers/controlCookies';
import ChatListFilters from './components/ChatListFilters/ChatListFilters';
import styled from 'styled-components';
import {Skeleton} from 'antd';
import {useNavigate} from 'react-router-dom';
import ChatListWrapper from "../../components/ChatWrappers/ChatListWrapper";
import {IS_SHOW_CHAT} from "../../../src/packages/keycloak-client/constants";

const ChatList = () => {
    const setCurrentChat = useChatListState((state) => state.setCurrentChat);
    const typeTabs = useChatListState((state) => state.typeTabs);
    const setTypeTabs = useChatListState((state) => state.setTypeTabs);
    const isOpenChat = useChatListState((state) => state.isOpenChat);
    const openChatId = useChatListState((state) => state.openChatId);
    const resetFields = useChatListState((state) => state.resetFields);
    const isLoadingSkeleton = useChatListState(
        (state) => state.isLoadingSkeleton
    );
    const navigate = useNavigate();

    const partnersChatsList = useChatListState(
        (state) => state.partnersChatsList
    );
    const phexChatsList = useChatListState((state) => state.phexChatsList);
    const setIsLoadingSkeleton = useChatListState(
        (state) => state.setIsLoadingSkeleton
    );

    useEffect(() => {
        resetFields();
        return () => resetFields();
    }, []);

    useEffect(() => {
        if (openChatId) {
            setCurrentChat()
        }

    }, [typeTabs, partnersChatsList, phexChatsList, openChatId])

    const handleTatyTabs = (value: TTabs) => {
        setIsLoadingSkeleton(true);
        setTypeTabs(value);
    };

    const isShowChat = IS_SHOW_CHAT;

    return (
        <>
            {isShowChat && (
                <ChatListStyled>
                    <ChatListLeftStyled $isOpen={isOpenChat}>
                        <ChatListHeader onCrossClick={() => navigate(-1)}/>
                        {!!getCookieByName('phexChatToken') && (
                            <ChatListFilters typeTabs={typeTabs} setTypeTabs={handleTatyTabs}/>
                        )}
                        {isLoadingSkeleton ? (
                            <WrapSkeletonContainerStyled>
                                {Array.from({length: 6}).map((el, i) => (
                                    <WrapSkeletonStyled key={i} active/>
                                ))}
                            </WrapSkeletonContainerStyled>
                        ) : (
                            <ChatListItems
                                typeTabs={typeTabs}
                                items={typeTabs === 'personal' ? partnersChatsList : phexChatsList}
                            />
                        )}
                    </ChatListLeftStyled>
                    <ChatListRightStyled $isOpen={isOpenChat}>
                        <ChatListWrapper/>
                    </ChatListRightStyled>
                </ChatListStyled>
            )}
        </>
    );
};

export default ChatList;

export const WrapSkeletonStyled = styled(Skeleton.Input)`
    display: block;

    &&& {
        width: 100%;
        height: 100px;
    }
`;

export const WrapSkeletonContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
