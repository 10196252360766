import { useTranslation } from 'react-i18next';
import { Statistic } from 'antd';

interface IProps {
  deadline: string | number;
  isOff?: boolean;
}

const GenericTimer = ({ deadline, isOff }: IProps) => {
  const { t } = useTranslation();

  const givenDate = new Date(deadline);
  const currentDate = new Date();

  const { Countdown } = Statistic;
  return (
    <Countdown
      value={deadline}
      format={isOff ? '-- : -- : --' : `DD ${t('д')} : HH ${t('ч')} : mm`}
      suffix={!isOff && t('м')}
      className={currentDate > givenDate ? 'disabled' : ''}
    />
  );
};

export default GenericTimer;
