import {
  ChatListItemOfferStepRightDealTextStyled,
  ChatListItemOfferStepRightStyled,
  ChatListItemOfferStepRightTitleStyled,
  ChatListItemOfferStepStyled,
} from './assets/styles';
import ChatListItemOfferStepTimer from './components/Timer/ChatListItemOfferStepTimer';
import Exclamation from './assets/exclamation.svg';
import InProgress from './assets/inProgress.svg';
import Provision from './assets/provision.svg';
import { useTranslation } from 'react-i18next';
import OutTime from './assets/outTime.svg';
import Check from './assets/check.svg';
import Ready from './assets/ready.svg';
import Temp from './assets/temp.svg';
import Done from './assets/done.svg';
import Cancel from './assets/cancel.svg';
import { memo } from 'react';

type TChatListItemOfferStep = {
  step?: string;
  id?: string;
  closedAt: null | string;
};

const ChatListItemOfferStep = ({
  step,
  id,
  closedAt,
}: TChatListItemOfferStep) => {
  const { t } = useTranslation();

  const renderStatus = {
    pending: {
      icon: <img src={InProgress} alt={'pending'} />,
      text: t('На рассмотрении'),
    },
    accepted: {
      icon: <img src={Check} alt={'accepted'} />,
      text: t('Принята'),
    },
    warranty_collateral: {
      icon: <img src={Provision} alt={'warranty_collateral'} />,
      text: t('Обеспечение гаранта'),
    },
    ready: {
      icon: <img src={Ready} alt={'ready'} />,
      text: t('Готова к обмену'),
    },
    completed: {
      icon: <img src={Done} alt={'completed'} />,
      text: t('Выполнена'),
    },
    expired: {
      icon: <img src={OutTime} alt={'expired'} />,
      text: t('Просрочена'),
    },
    on_argument: {
      icon: <img src={Exclamation} alt={'on_argument'} />,
      text: t('Открыт спор'),
    },
    cancelled: {
      icon: <img src={Cancel} alt={'cancelled'} />,
      text: t('Отменена'),
    },
  };

  // TODO: Добавить локализацию
  const renderIcon = () => {
    if (!!closedAt) {
      return <img src={Temp} alt={'Temp'} />;
    } else if (step) {
      return renderStatus[step as keyof typeof renderStatus].icon;
    }
    return null;
  };
  const renderStepText = () => {
    if (closedAt) {
      return <ChatListItemOfferStepTimer closedAt={closedAt} />;
    } else if (step) {
      return (
        <ChatListItemOfferStepRightTitleStyled>
          {renderStatus[step as keyof typeof renderStatus].text}
        </ChatListItemOfferStepRightTitleStyled>
      );
    }
    return null;
  };
  const renderDealText = () => {
    if (closedAt) {
      return (
        <ChatListItemOfferStepRightDealTextStyled>
          {t('Временный чат')}
        </ChatListItemOfferStepRightDealTextStyled>
      );
    } else if (step && id) {
      return (
        <ChatListItemOfferStepRightDealTextStyled>
          {t('Сделка')} {id}
        </ChatListItemOfferStepRightDealTextStyled>
      );
    }
  };

  return (
    <ChatListItemOfferStepStyled>
      {renderIcon()}
      <ChatListItemOfferStepRightStyled>
        {renderStepText()}
        {renderDealText()}
      </ChatListItemOfferStepRightStyled>
    </ChatListItemOfferStepStyled>
  );
};

export default memo(ChatListItemOfferStep);
