import styled from "styled-components";

export const ChatListItemOfferStepStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export const ChatListItemOfferStepRightStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const ChatListItemOfferStepRightTitleStyled = styled.div`
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: #DEDEE3;
`;

export const ChatListItemOfferStepRightDealTextStyled = styled.div`
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #767687;
`;
